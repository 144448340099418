import { AvCheckbox, AvCheckboxGroup, AvField } from "availity-reactstrap-validation"
import PropTypes from "prop-types"
import { Label, Row } from "reactstrap"
//i18n
import { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"

const FilterForm = forwardRef(({ model, t, groups }, ref) => {
    const [grData, setGrData] = useState([])
    const [isStatus, setIsStatus] = useState(model.isStatus || true)
    const [isGroup, setIsGroup] = useState(model.isGroup || true)

    useEffect(() => {
        setGrData(model.group || [])
    }, [model.group])
    useEffect(() => {
        setIsStatus(model.isStatus)
    }, [model.isStatus])
    useEffect(() => {
        setIsGroup(model.isGroup)
    }, [model.isGroup])
    useEffect(() => {
    }, [groups])

    useImperativeHandle(ref, () => ({
        onRefresh: () => {
            setGrData([])
            document.getElementById("filter-form-result-detail").click()
        },
    }));
    return (
        <>
            <button className="d-none" type="reset" id='filter-form-result-detail'></button>
            <Row className="filter-form-result-detail">
                <div className="mb-2">
                    <div className="d-flex">
                        <Label style={{ marginRight: '5px' }} for="isGroup">{t("common:Group")}</Label>
                        {/* <AvField type="checkbox" name="isGroup" label={t("isGroup")} checked={isGroup} value={isStatus}
                            onChange={e => {
                                setIsGroup(e.target.checked)
                            }}
                        /> */}
                    </div>
                    {/* <div className={isGroup == true ? "" : "diable-filter-form-table"}> */}
                    <div>
                        <AvCheckboxGroup name="group" label="" value={grData}
                        >
                            <AvCheckbox label={t("common:All")} checked={grData.findIndex(x => x == '') >= 0} onChange={(e) => {
                                if (e.target.checked == true) {
                                    setGrData([...(groups.map(x => x.value)), ''])
                                }
                                else {
                                    setGrData([])
                                }
                            }} />
                            {groups && groups.map((row, index) => <AvCheckbox key={index}
                                checked={grData.findIndex(x => x == row.value) >= 0}
                                label={row.label} value={row.value}
                                onChange={(e) => {
                                    let tmp = grData;
                                    if (e.target.checked == false) {
                                        tmp = tmp.filter(x => x != row.value)
                                        setGrData([...tmp])
                                    }
                                    else {
                                        tmp.push(row.value)
                                        setGrData([...tmp])
                                    }
                                }}
                            />)}
                        </AvCheckboxGroup>
                    </div>
                </div>
                <div className="mb-2">
                    <div className="d-flex">
                        <Label style={{ marginRight: '5px' }} for="isStatus">{t("common:Status")}</Label>
                    </div>
                    <div>
                        {/* <div className="form-check">
                            <AvField type="checkbox" name="printed" label={t("Printed")} value={model.printed} />
                            <label htmlFor="printed" className="form-check-label form-label">{t("Printed")}</label>
                        </div> */}
                        <div className="form-check">
                            <AvField type="checkbox" name="notPrinted" label={t("testResultPage:Not printed")} value={model.notPrinted} />
                            <label htmlFor="notPrinted" className="form-check-label form-label">{t("testResultPage:Not printed")}</label>
                        </div>
                        <div className="form-check">
                            <AvField type="checkbox" name="fullResult" label={t("Result")} value={model.fullResult} />
                            <label htmlFor="fullResult" className="form-check-label form-label">{t("Has Result")}</label>
                        </div>
                        <div className="form-check">
                            <AvField type="checkbox" name="confirmed" label={t("Confirmed")} value={model.confirmed} />
                            <label htmlFor="confirmed" className="form-check-label form-label">{t("Confirmed")}</label>
                        </div>
                        <div className="form-check">
                            <AvField type="checkbox" name="valid" label={t("Validated")} value={model.valid} />
                            <label htmlFor="valid" className="form-check-label form-label">{t("Validated")}</label>
                        </div>
                    </div>
                </div>
            </Row>

        </>
    )
})

FilterForm.propTypes = {
    model: PropTypes.object,
    t: PropTypes.any
}

// export default withTranslation(["testResultPage", "common"])(FilterForm)
const mapStateToProps = ({ testResult }) => ({
})

const mapDispatchToProps = dispatch => ({
})

FilterForm.displayName = 'FilterForm';
export default withTranslation(["testResultPage", "common"], { withRef: true })(connect(
    mapStateToProps,
    mapDispatchToProps, null, { forwardRef: true }
)(FilterForm))