import { CustomButton, Header, showErrToast } from "components/Common"
import CustomSplitPane from "components/Common/CustomSplitPaneLeft"
import ModalConfigPrinter from "components/Common/Modals/ModalConfigPrinter"
import ModalConfigPrinterPatientVisit from "components/Common/Modals/ModalConfigPrinterPatientVisit"
import { ModuleIds, ReportResource } from "constant"
import { getAllOrganizations, getAuthorizePage, getFilePrint, pingToPrintService, sendDataPrint } from "helpers/app-backend"
import { getPatientVisitCollectedList, getPatientVisitWaitingList, getPatientVisitWaitingRequestList } from "helpers/app-backend/testRequest_backend_helper"
import {
  getHisPatient,
} from "helpers/fakebackend_helper"
import {
  GetDataUrlReportPatientVisitConfig,
  GetResourceReportConfig,
} from "helpers/utilities"
import { isEmpty } from "lodash"
import React, { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect, useSelector } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { Row } from "reactstrap"
import {
  ChangeRequestSampleTableSuccess, clearDetailInfoPatientVisit, createNewRequest,
  getPatientRequestDetail,
  getTestRequestDetail,
  getTestRequestPatientVisitDetail,
  getTestrequestGeneralDetail,
  updateRequestSID
} from "store/actions"
import GroupRequestModal from "./GroupPatient"
import SettingModal from "./Modal/SettingModal"
import LeftFrame from "./PageVisitArea/LeftArea/LeftFrame"
import RightFrame from "./PageVisitArea/RightArea/RightFrame"
import RequestHistoryModal from "./RequestHistoryModal"

const RESOURCE = ModuleIds.PatientVisit
let sizeWaitingGlobal = 20;
let sizeCollectedGlobal = 20;

const PatientVisit = ({
  t,
  patientVisit,
  fetchGetTestrequestGeneralDetail,
  onChangeRequestSampleTable,
  onAddNewRequest,
  onClearDetailInfoPatientVisit,
  onUpdateSampleId
}) => {
  const [groupModal, setGroupModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [openReport, setOpenReport] = useState(false)
  const [open, setOpen] = useState(false)
  const [patientDetail, setPatientDetail] = useState({})
  const [patientCollectedDetails, setPatientCollectedDetails] = useState({})
  const [settingModal, setSettingModal] = useState(false)
  const [patientHis, setPatientHis] = useState({})
  const [visitPatient, setVisitPatient] = useState([])
  const [wattingPatient, setWattingPatient] = useState([])
  const [toCollectPatient, setToCollectPatient] = useState([])
  const [disabledAdd, setDisabledAdd] = useState(true)
  const [patientVisitConfig, setPatientVisitConfig] = useState(
    JSON.parse(localStorage.getItem("patientVisitConfig"))
  )
  const [reportResultInfos, setReportResultInfos] = useState({})
  const [disableActionReport, setDisableActionReport] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [filter, setFilter] = useState({})
  const [actionState, setActionState] = useState('')
  const [disableAction, setDisableAction] = useState(false)
  const [actionStateReport, setActionStateReport] = useState('')
  const [page, setPage] = useState(1)
  const [sizeRequestWaiting, setSizeRequestWaiting] = useState(sizeWaitingGlobal)
  const [sizeWaiting, setSizeWaiting] = useState(sizeWaitingGlobal)
  const [sizeCollected, setSizeCollected] = useState(sizeCollectedGlobal)
  const [loadingBarcode, setLoadingBarcode] = useState(false)
  const [modal, setModal] = useState(false)
  const [tabActiveRightFrame, setTabActiveRightFrime] = useState('1')
  const [requestDate, setRequestDate] = useState('')
  const [pagingWaiting, setPagingWaiting] = useState({
    dataSize: "",
    page: "",
    size: "",
    totalPages: ""
  })
  const [pagingCollected, setPagingCollected] = useState({
    dataSize: "",
    page: "",
    size: "",
    totalPages: ""
  })
  const formEl = useRef()
  const [organizations, setOrganizations] = useState([])
  const [activeTab, setActiveTab] = useState('')
  const [historyModal, setHistoryModal] = useState(false)

  const getReportResultInfos = async () => {
    const res = await getAuthorizePage(1, "_PatientVisit")
    if (res) {
      setReportResultInfos(res._ReportInfo)
    }
  }

  useEffect(() => {
    getReportResultInfos();
    getOrganizations()
    return () => {
      setPatientHis({})
      onClearDetailInfoPatientVisit()
    }
  }, [])

  useEffect(() => {
    setPatientVisitConfig(
      JSON.parse(localStorage.getItem("patientVisitConfig"))
    )
  }, [settingModal])
  const toggleSetting = () => {
    setSettingModal(prev => !prev)
  }

  const toggleBarcodeSetting = () => {
    setOpen(prev => !prev)
  }

  const toggleReportSetting = () => {
    setOpenReport(prev => !prev)
  }

  const onChangePatient = (name, value) => {
    setPatientDetail({
      ...patientDetail,
      [name]: value,
    })
  }

  const toggle = () => {
    if (!patientVisitConfig?.autoSID) {
      setModal(!modal)
    }
  }

  const { reportInfos } = useSelector(state => ({
    reportInfos: state.Authorization.reportinfo || [],
  }))

  const io1 = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (!entry.isIntersecting) {
        return;
      }
      sizeWaitingGlobal += 20;
      setSizeRequestWaiting(sizeWaitingGlobal)
    });
  });

  const io = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (!entry.isIntersecting) {
        return;
      }
      sizeWaitingGlobal += 20;
      setSizeWaiting(sizeWaitingGlobal)
    });
  });

  const io2 = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (!entry.isIntersecting) {
        return;
      }
      sizeCollectedGlobal += 20;
      setSizeCollected(sizeCollectedGlobal)
    });
  });
  let elEnd1 = document.getElementById(`watch_end_of_document1`)
  let elEnd2 = document.getElementById(`watch_end_of_document2`)
  let elEnd3 = document.getElementById(`watch_end_of_document3`)


  useEffect(() => {
    if (elEnd1) {
      io1.observe(elEnd1)
    }
  }, [elEnd1])

  useEffect(() => {
    if (elEnd2) {
      io.observe(elEnd2)
    }
  }, [elEnd2])

  useEffect(() => {
    if (elEnd3) {
      io2.observe(elEnd3)
    }
  }, [elEnd3])

  const getOrganizations = async () => {
    const res = await getAllOrganizations({ page: 1, size: 0 })
    setOrganizations(res.data)
  }

  useEffect(() => {
    if (organizations.length > 0) {
      onRefreshHandler('2')
    }
  }, [organizations])


  const onSelectPatient = async (e, row, rowIndex) => {
    if (patientDetail && patientDetail.id != row.id) {
      onChangeRequestSampleTable([])
    }
    await fetchGetTestrequestGeneralDetail(row.id, () => {
      setTabActiveRightFrime('2')
    }, ModuleIds.PatientVisit);
    setPatientDetail(row)
    setDisabledAdd(false)
  }

  const addNewRequest = (request, dataHis) => {
    onAddNewRequest({
      request: request,
      callback: ({ id }) => {
        fetchGetTestrequestGeneralDetail(id)
        let newWattingPatient = wattingPatient.filter(el => {
          return el.patientId != patientDetail.patientId
        })
        let newVisitPatient = visitPatient
        newVisitPatient.unshift(patientDetail)
        setWattingPatient(newWattingPatient)
        setVisitPatient(newVisitPatient)
      },
    })
  }

  const updateRequestSample = payload => {
    onChangeRequestSampleTable(payload)
  }

  useEffect(() => {
    setPatientHis(patientVisit)
  }, [patientVisit])

  const fetchPatientRequestWaitingList = async (searchText, values) => {
    const res = await getPatientVisitWaitingRequestList({ size: sizeWaiting, search: searchText, ...values })
    for (const item of res.data) {
      const result = organizations.find(x => x.organizationCode == item.groupCode)
      item.organizationName = result?.name || ''
    }
    if (res !== null)
      setLoading(false)
    setPagingWaiting({
      dataSize: res.totalElements,
      page: res.page,
      size: page.size,
      totalPages: res.totalPages
    })
    setWattingPatient([...res.data])
    setActiveTab('1')
    if (activeTab == '1') {
      if (res && res.data && res.data.length == 1) {
        setTabActiveRightFrime(null)
        onSelectPatient('', res.data[0])
      }
    }
  }


  const fetchPatientWaitingList = async (searchText, values) => {
    const res = await getPatientVisitWaitingList({ size: sizeWaiting, search: searchText, ...values })
    for (const item of res.data) {
      const result = organizations.find(x => x.organizationCode == item.groupCode)
      item.organizationName = result?.name || ''
    }
    if (res !== null)
      setLoading(false)
    setPagingWaiting({
      dataSize: res.totalElements,
      page: res.page,
      size: page.size,
      totalPages: res.totalPages
    })
    setWattingPatient([...res.data])
    setActiveTab('2')
    if (activeTab == '2') {
      if (res && res.data && res.data.length == 1) {
        setTabActiveRightFrime(null)
        onSelectPatient('', res.data[0])
      }
    }
  }

  const fetchPatientCollectedList = async (searchText, values) => {
    const res = await getPatientVisitCollectedList({ size: sizeCollected, search: searchText, ...values })
    for (const item of res.data) {
      const result = organizations.find(x => x.organizationCode == item.groupCode)
      item.organizationName = result?.name || ''
    }
    if (res !== null)
      setLoading(false)
    setPagingCollected({
      dataSize: res.totalElements,
      page: res.page,
      size: page.size,
      totalPages: res.totalPages
    })
    setToCollectPatient([...res.data])
    setActiveTab('3')
    if (activeTab == '3') {
      if (res && res.data && res.data.length == 1) {
        setTabActiveRightFrime(null)
        onSelectPatient('', res.data[0])
      }
    }
  }
  const onRefreshHandler = (activeTab1) => {
    try {
      let check = document.querySelector("#patient-visit-sample-list-table thead th.selection-cell-header input");
      if (check.checked == true) {
        check.click()
      }
    } catch (error) {

    }

    setLoading(true)
    const tab = (activeTab1 || activeTab)
    if (tab == '1') {
      fetchPatientRequestWaitingList(searchText, filter)
    } else if (tab == '2') {
      fetchPatientWaitingList(searchText, filter)
    } else if (tab == '3') {
      fetchPatientCollectedList(searchText, filter)
    }
    setPatientDetail({})
  }

  const onSubmitHandler = (searchText, activeTab1) => {
    setSearchText(searchText)
    const tab = (activeTab1 || activeTab)
    if (tab == '1') {
      fetchPatientRequestWaitingList(searchText, filter)
    } else if (tab == '2') {
      fetchPatientWaitingList(searchText, filter)
    } else if (tab == '3') {
      fetchPatientCollectedList(searchText, filter)
    }
  }

  const onSubmitFilterHandler = (e, values, activeTab1) => {
    setFilter(values)
    const tab = (activeTab1 || activeTab)
    if (tab == '1') {
      fetchPatientRequestWaitingList(searchText, values)
    } else if (tab == '2') {
      fetchPatientWaitingList(searchText, values)
    } else if (tab == '3') {
      fetchPatientCollectedList(searchText, values)
    }

  }


  useEffect(() => {
    fetchPatientRequestWaitingList(searchText, filter)
  }, [sizeRequestWaiting])

  useEffect(() => {
    fetchPatientWaitingList(searchText, filter)
  }, [sizeWaiting])

  useEffect(() => {
    fetchPatientCollectedList(searchText, filter)
  }, [sizeCollected])

  const onAddRequest = async () => {
    if (!patientDetail.sid && !patientVisitConfig.autoSID) {
      setDisabledAdd(true)
      showErrToast(
        `${t("message:SID is empty", {
          field: `${t(
            "hisConnectorPage:HisConnector"
          )} <span class='text-decoration-underline fw-bold'>SID is empty</span>`,
        })}`
      )
    } else {
      await getHisPatient(patientDetail).then(res => {
        setDisabledAdd(true)
        if (res) {
          let samples = []
          res.tests.forEach(item => {
            samples.push({
              sid: patientVisitConfig.autoSID ? 0 : patientDetail.sid,
              sampleType: item.SampleType,
              sampleTypeName: item.SampleTypeName,
              numberOfLabels: 1,
              collectorUserId: null,
              isCollected: false,
              receiverUserId: null,
              isReceived: false,
              quality: "G",
            })
          })
          updateRequestSample(samples)
          let newTestRequest = []
          let newProfileRequest = []
          for (let i = 0; i < res.tests.length; i++) {
            if (res.tests[i].Children == null) {
              newTestRequest.push({
                testCode: res.tests[i].TestCode,
                testName: res.tests[i].TestName,
                testCategory: res.tests[i].Category,
                sampleType: res.tests[i].SampleType,
              })
            } else {
              newProfileRequest.push({
                profileCode: res.tests[i].TestCode,
                profileCategory: res.tests[i].Category,
              })
              res.tests[i].Children.forEach(element => {
                newTestRequest.push({
                  testCode: element.TestCode,
                  testName: element.TestName,
                  testCategory: element.Category,
                  sampleType: element.SampleType,
                  profileCode: res.tests[i].TestCode,
                })
              })
            }
          }
          const request = {
            individualValues: { ...patientDetail },
            tests: newTestRequest,
            profiles: newProfileRequest,
            samples: samples,
            ...patientDetail,
          }
          addNewRequest(request, { ...res, samples: samples })
        }
      })
    }
  }

  const PrintConfig = async () => {
    const printAppointment = JSON.parse(localStorage.getItem("printConfigReport"))
    if (reportResultInfos.length > 0 && !isEmpty(reportResultInfos[0].uri)) {
      const res = await getFilePrint({
        ...GetDataUrlReportPatientVisitConfig(reportResultInfos, ReportResource.PatientVisit, ""),
        ResultId: patientHis.resultId,
        PatientVisit: patientHis.id,
        RequestDate: patientHis.requestDate,
        PatientId: patientHis.patientId,
        Gender: patientHis.gender,
      })
      try {
        const getPingPrintService = await pingToPrintService();
        if (getPingPrintService.pingTime) {
          const dataSendPrint = await sendDataPrint({
            ...GetDataUrlReportPatientVisitConfig(reportResultInfos, ReportResource.PatientVisit, ""),
            "filePath": `${res.reportUrl}`,
            // "quantity": rowState.numberOfLabels
          })
        } else {
          window.open(process.env.REACT_APP_BASE_ENDPOINT + res?.reportUrl)
        }
      }
      catch {
        window.open(process.env.REACT_APP_BASE_ENDPOINT + res?.reportUrl)
      }
    }
    else {
      console.log('failed')
    }
  }

  const handlePrintResultAppointment = () => {
    PrintConfig()
  }

  const onRequestDateChange = (value) => {
    setRequestDate(value)
  }

  const openHistory = async () => {
    setHistoryModal(true)
  }

  const height = window.innerHeight > 750 ? `calc(${window.innerHeight}px - 140px)` : '765px'

  return (
    <React.Fragment>
      <div id="result-header-detail">
        <Row>
          <ModalConfigPrinterPatientVisit open={openReport} onClose={(state) => {
            setDisableActionReport(false)
            setOpenReport(false)
          }}
            disableAction={disableActionReport}
            stateAction={actionStateReport}
            resource={GetResourceReportConfig(reportResultInfos)}
            onPreviewClick={(state) => { onPreviewHandler(state) }}
            onPrintClick={(state) => { onPrintHandler(state) }}
            onExportClick={(state) => { onExportHandler(state) }}
          />
          <ModalConfigPrinter open={open} onClose={(state) => {
            setDisableAction(false)
            setOpen(false)
          }}
            disableAction={disableAction}
            stateAction={actionState}
            resource={GetResourceReportConfig(reportInfos)}
            onPreviewClick={(state) => { onPreviewHandler(state) }}
            onPrintClick={(state) => { onPrintHandler(state) }}
            onExportClick={(state) => { onExportHandler(state) }}
          />
          <div className="d-flex p-2 bg-white border-bottom">
            <div className="col-6">
              <Header
                title={t("testRequestPage:Process management")}
                resource={RESOURCE}
                subtitle={t("Patient Visit")}
              />
            </div>
            <div className="d-flex col-6 gap-1 justify-content-end">
              <CustomButton
                text={t("common:Save")}
                type="submit"
                isEdit
                color="success"
                className="save-user button-width"
                disabled={isEmpty(patientVisit)}
                onClick={() => {
                  formEl.current.onSave()
                }}
              >
                {t("common:Save")}
              </CustomButton>
              <Link
                to={`/PatientGroup`}
              >
                <CustomButton
                  outline
                  text={t("testRequestPage:Patient Group")}
                  color="success"
                  className="save-user button-width"
                  onClick={() => {
                    setGroupModal(true)
                  }}
                >
                  <i className="mdi mdi-account-multiple px-1"></i>
                  {t("testRequestPage:Patient Group")}
                </CustomButton>
              </Link>
              <CustomButton
                className="loading-spin-hover"
                color="secondary"
                outline
                onClick={openHistory}
              >
                <i className="fas fa-history"></i>
              </CustomButton>
              <CustomButton
                className="loading-spin-hover"
                color="secondary"
                outline
                onClick={toggleReportSetting}
              >
                <i className="fa fa-file"></i>
              </CustomButton>
            </div>
          </div>
        </Row >
      </div >
      <div className="row position-relative" style={{ height: height, paddingTop: 5 }}>
        {loadingBarcode &&
          <div className="position-absolute h-100 w-100" style={{ zIndex: 10, backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
            <div className="w-100 h-100"><div className="text-center" style={{ marginTop: 50 }}>
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
            </div>
          </div>
        }
        <CustomSplitPane
          LeftFrame={() => (
            <RightFrame
              onSelectPatient={onSelectPatient}
              patientDetail={patientDetail}
              onRefresh={onRefreshHandler}
              toggle={toggle}
              toggleBarcodeSetting={toggleBarcodeSetting}
              onSubmit={onSubmitHandler}
              onSubmitFilter={onSubmitFilterHandler}
              loading={loading}
              visitPatient={visitPatient}
              wattingPatient={wattingPatient}
              toCollectPatient={toCollectPatient}
              pagingWaiting={pagingWaiting}
              pagingCollected={pagingCollected}
              requestDate={requestDate}
              onChangeTab={(val) => { setTabActiveRightFrime(val) }}
              onSelectBarcode={e => {
                setLoadingBarcode(e)
              }}
            />
          )}
          RightFrame={() => (
            <LeftFrame
              patientDetail={patientDetail}
              patientCollectedDetails={patientCollectedDetails}
              toggle={toggleSetting}
              onRefresh={onRefreshHandler}
              toggleReportSetting={toggleReportSetting}
              onChangePatient={onChangePatient}
              onClickAddRequest={onAddRequest}
              patientHis={patientHis}
              disabledAdd={disabledAdd}
              config={patientVisitConfig}
              ref={formEl}
              onRefreshInfo={onSelectPatient}
              tabActive={tabActiveRightFrame}
              onDateChange={onRequestDateChange}
            />
          )}
          InitialSize={66}
        />
      </div>
      <SettingModal
        modal={settingModal}
        toggle={() => {
          setSettingModal(false)
        }}
      />
      <GroupRequestModal
        modal={groupModal}
        toggle={() => {
          setGroupModal(false)
        }}
      />
      <RequestHistoryModal
        modal={historyModal}
        data={patientDetail}
        toggle={() => setHistoryModal(prev => !prev)}
      />
      {/* <ChangeSIDModal
        formEl={formEl}
        modal={modal}
        isEdit={false}
        onValidSubmit={handleValidSubmit}
        toggle={toggle}
        data={patientDetail}
      /> */}
    </React.Fragment>
  )
}
const mapStateToProps = ({ testRequest }) => ({
  patientVisit: testRequest.patientVisit,
})
const mapDispatchToProps = dispatch => ({
  onGetTestRequestDetail: requestId =>
    dispatch(getTestRequestDetail(requestId)),
  onGetTestRequestPatientVisitDetail: requestId =>
    dispatch(getTestRequestPatientVisitDetail(requestId)),
  onGetPatientRequestDetail: individualId =>
    dispatch(getPatientRequestDetail(individualId)),
  onAddNewRequest: payload => dispatch(createNewRequest(payload)),
  onChangeRequestSampleTable: payload =>
    dispatch(ChangeRequestSampleTableSuccess(payload)),
  fetchGetTestrequestGeneralDetail: (requestId, callback, type) =>
    dispatch(getTestrequestGeneralDetail(requestId, callback, type)),
  onClearDetailInfoPatientVisit: () =>
    dispatch(clearDetailInfoPatientVisit()),
  onUpdateSampleId: (payload) => dispatch(updateRequestSID(payload)),

})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["partyPage", "message", "common", "testRequestPage"])(PatientVisit)))
