import { CustomBootstrapTable, CustomButton, CustomSID } from "components/Common"
import IconStatus from "components/Common/IconStatus"
import * as constant from "constant"
import { StatusNote } from "constant"
import { convertDateFormat, indexCalculator } from "helpers/utilities"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import ReactTooltip from "react-tooltip"
import FilterForm from "./FilterForm"

const RESOURCE = constant.ModuleIds.AnalyticalProcess

const WorkOrderTable = ({
  onSelect,
  onSelectAll,
  tests,
  onSearch,
  onSort,
  onRefresh,
  onPageChange,
  paging,
  onSizePerPageChange,
  onSubmitFilter,
  loading,
  isSelected,
  t,
  updatedTime,
  onWorkOrderExport
}) => {
  const [data, setData] = useState([])
  const [model, setModel] = useState({
    search: "",
    testCode: "",
    downloadRejected: "",
    requestDate: null,
  })
  const searchQuery = useSelector(state => state.workOrder.searchQuery)
  const defaultSortValue = { dataField: "requestDate", order: "desc" }
  const onResetHandler = () => {
    const initModel = {
      search: "",
      testCode: "",
      downloadRejected: "",
      requestDate: null,
    }
    setModel(initModel)
  }

  useEffect(() => {
    setModel(searchQuery)
  }, [searchQuery])

  useEffect(() => {
    setData(tests.map(x => ({
      ...x, inRequestId: x.id, id: `${x.id}${x.instrumentTestCode}`
    })))
  }, [tests])

  const columns = [
    {
      dataField: "id",
      text: "",
      hidden: true,
      formatter: (cellContent, test, index) => {
        return cellContent
      },
    },
    {
      dataField: "SEQ",
      text: "#",
      headerStyle: { width: "30px" },
      formatter: (cellContent, test, index) => {
        return indexCalculator(paging.page, paging.size, index + 1)
      },
    },
    {
      dataField: "requestDate",
      text: t("Request Date"),
      sort: true,
      style: { color: "#556ee6", width: "150px" },
      formatter: (cellContent, test, index) => {
        return (
          <Link
            to={`/TestRequest/${test.requestId}/view?tab=1`}
            target="_blank"
          >
            {convertDateFormat(test.requestDate, "DD-MM-YYYY")}
          </Link>
        )
      },
    },
    {
      dataField: "sid",
      text: t("SID"),
      sort: true,
      formatter: (cellContent, item, index) => {
        return <CustomSID
          key={`sampleType-${index}`}
          keyToolTip={`sampleType-${index}`}
          // isToolTip={true}
          // toolTip={item.sampleTypeName}
          sid={item.subSID > 0 ? `${cellContent} - ${item.subSID}` : cellContent}
        // emergency={item?.emergency}
        />
      }
    },
    {
      dataField: "testCode",
      text: t("Test Code"),
      // formatter: (cellContent, test, index) => {
      //   return (
      //     <Link to={`/Test/${cellContent}/view?tab=1`} target="_blank">
      //       {cellContent}
      //     </Link>
      //   )
      // },
    },
    {
      dataField: "instrumentTestCode",
      text: t("Ins Code"),
      style: { width: "90px" },
    },
    {
      dataField: "testName",
      text: t("Test Name"),
      style: { width: "350px" },
    },
    {
      dataField: "download",
      text: t("Downloaded"),
      style: { minWidth: "85px" },
      align: "center",
      headerAlign: "center",
      headerFormatter: (column, colIndex) => (
        <>
          <div>
            <ReactTooltip
              id={`tooltip-custom${colIndex}`}
              place="top"
              effect="solid"
            >
              {t("Downloaded-Tooltip")}
            </ReactTooltip>
            <div >
              {column.text}
              <i data-tip data-for={`tooltip-custom${colIndex}`} className="fas fa-exclamation-circle text-primary" style={{ marginLeft: 10 }}></i>
            </div>
          </div>
        </>
      ),
      formatter: (cellContent, test, index) => {
        return (
          <IconStatus
            isRound={true}
            type={cellContent ? "Active" : "Inactive"}
          />
        )
      },
    },
    {
      dataField: "downloadRejected",
      text: t("Suspended"),
      style: { minWidth: "85px" },
      align: "center",
      headerAlign: "center",
      headerFormatter: (column, colIndex) => (
        <>
          <div>
            <ReactTooltip
              id={`tooltip-custom${colIndex}`}
              place="top"
              effect="solid"
            >
              {t("Suspended-Tooltip")}
            </ReactTooltip>
            <div >
              {column.text}
              <i data-tip data-for={`tooltip-custom${colIndex}`} className="fas fa-exclamation-circle text-primary" style={{ marginLeft: 10 }}></i>
            </div>
          </div>
        </>
      ),
      formatter: (cellContent, test, index) => {
        return (
          <IconStatus
            isRound={true}
            type={cellContent ? "Active" : "Inactive"}
          />
        )
      },
    },
    {
      dataField: "statusNote",
      text: t("Status Note"),
      sort: false,
      headerFormatter: (column, colIndex) => (
        <>
          <div>
            <ReactTooltip
              id={`tooltip-custom${colIndex}`}
              place="top"
              effect="solid"
            >
              {t("Status Note-Tooltip")}
            </ReactTooltip>
            <div >
              {column.text}
              <i data-tip data-for={`tooltip-custom${colIndex}`} className="fas fa-exclamation-circle text-primary" style={{ marginLeft: 10 }}></i>
            </div>
          </div>
        </>
      ),
      formatter: (cellContent, test, index) => {
        const statusNote = t(
          `${StatusNote[`${+test.download}${+test.downloadRejected}`]}`
        )
        const classes =
          statusNote === "Not Download"
            ? "badge-soft-primary ms-1 badge rounded-pill"
            : "badge-soft-dark ms-1 badge rounded-pill"
        return <span className={classes}>{statusNote}</span>
      },
    },
    {
      dataField: "instrumentId",
      text: t("Ins Id"),
      style: { fontWeight: "bold" },
      align: "center",
      headerAlign: "center",
      formatter: (cellContent, test, index) => {
        return (
          <>
            {test.instrumentName ? (
              <span data-tip data-for={test.instrumentName}>
                {cellContent}
              </span>
            ) : (
              "---"
            )}
            {test.instrumentName && (
              <ReactTooltip id={test.instrumentName} place="top" effect="solid">
                <span>{test.instrumentName}</span>
              </ReactTooltip>
            )}
          </>
        )
      },
    },
    // {
    //   dataField: "normalRange",
    //   text: t("Normal Range"),
    // },
    {
      dataField: "categoryName",
      text: t("Category"),
      formatter: (cellContent, test, index) => {
        const splitCellContent = cellContent.split(" > ")
        if (splitCellContent[0] === splitCellContent[1]) {
          return splitCellContent[0]
        } else {
          return cellContent
        }
      },
    },
    {
      dataField: "downloadTime",
      text: t("Download Time"),
      formatter: (cellContent, item, index) => convertDateFormat(cellContent),
    },
  ]

  return (
    <div>
      <CustomBootstrapTable
        columns={columns}
        search
        data={data}
        paging={paging}
        onSelect={onSelect}
        onSelectAll={onSelectAll}
        onSearch={onSearch}
        onSort={onSort}
        isSelected={isSelected}
        onRefresh={onRefresh}
        filterForm={() => <FilterForm model={model} />}
        onReset={onResetHandler}
        onPageChange={onPageChange}
        onSizePerPageChange={onSizePerPageChange}
        onSubmitFilter={onSubmitFilter}
        searchText={model.search}
        loading={loading}
        updatedTime={updatedTime}
        resource={RESOURCE}
        keyField="id"
        defaultSortValue={defaultSortValue}
        isImportExportButton={true}
        importExportForm={() => <div className="tooltip-custom">
          <CustomButton
            isEdit
            color="secondary"
            outline
            onClick={onWorkOrderExport}
          >
            <i className={"fas fa-download"}></i>
          </CustomButton>
          <span className="tooltiptext-custom-button-icon-left">
            {t("Export")}
          </span>
        </div>}
      />
    </div>
  )
}

export default withTranslation(["workOrderPage"])(WorkOrderTable)
