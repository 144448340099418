import { AvForm } from "availity-reactstrap-validation"
import { CustomButton, CustomButtonGroup } from "components/Common"
import { ModuleIds, textFieldTypes } from "constant"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
//i18n
import DynamicFormForSetting from "components/Common/Input/DynamicFormForSetting"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Col, Label, Row } from "reactstrap"
import { getGeneralSetting, getSettingField, updateGeneralSetting } from "store/setting/generalSetting/actions"
import TreeViewBar from './TreeViewBar'

const RESOURCE = ModuleIds.GeneralSetting

const GeneralSetting = ({
    t,
    onUpdateGeneralSetting,
    onGetSettingField,
    onGetGeneralSetting,
    generalSetting,
    settingField,
    companies
}) => {

    const [dataGeneral, setDataGeneral] = useState(null)
    const [field, setField] = useState([])
    const [group, setGroup] = useState('')
    const userInfo = JSON.parse(localStorage.getItem("userInfo"))
    const [isView, setIsView] = useState(true);
    const [company, setCompany] = useState(userInfo?.company)
    const [companyInfo, setCompanyInfo] = useState({})

    const groups = field.filter(item => item.type === textFieldTypes.GROUP).map(item => ({ ...item, shortName: item.fieldName }))
    const groupIndex = field.findIndex(item => group.id === item.id);

    const [fieldOfGroup, setFieldOfGroup] = useState([])

    useEffect(() => {
        for (let i = 0; i < field.length; i++) {
            if (i > groupIndex + 1 && field[i].type === textFieldTypes.GROUP) {
                break;
            }
            if (i > groupIndex) {
                fieldOfGroup.push(field[i])
            }
        }
        setFieldOfGroup([...fieldOfGroup])
    }, [group, company])


    useEffect(() => {
        onGetGeneralSetting(company);
        onGetSettingField();
    }, [])

    useEffect(() => {
        if (companies?.length > 0) {
            const data = companies.find(item => item.id === parseInt(userInfo?.company))
            setCompanyInfo(data)
        }
    }, [companies])

    useEffect(() => {
        onGetGeneralSetting(company);
        onGetSettingField();
    }, [company])

    useEffect(() => {
        if (settingField) {
            settingField.forEach((item) => {
                item.fieldName = item.fieldCode
                item.typeName = item.type
            })
            setField([...settingField])
        }
    }, [settingField])

    useEffect(() => {
        if (generalSetting && field.length > 0) {
            let result = {}
            field.forEach((item) => {
                generalSetting?.forEach((el) => {
                    if (el.fieldName === item.fieldName) {
                        item.value = el.fieldValue
                    }
                })
            })
            setField([...field])
            generalSetting?.forEach((item) => {
                result[item.fieldName] = item.fieldValue
            })
            setDataGeneral(JSON.parse(JSON.stringify(result)));
            setIsView(false)
        }

    }, [generalSetting, settingField])

    const onValidSubmit = (e, values) => {
        const result = {
            companyId: dataGeneral?.companyId || company,
            settingFieldValues: {
                ...values
            }
        }
        if (values.isYearly !== null && values.isYearly !== undefined) {
            const holidayKey = Object.keys(values).filter((item) => item.includes('dateHoliday'));
            let holidayDate = []
            holidayKey.forEach((item, index) => {
                holidayDate.push({ dateHoliday: values[`dateHoliday-${index}`], descriptionHoliday: values[`descriptionHoliday-${index}`] })
            })
            result['settingFieldValues']['WorkHoliday'] = { holidayDate, isYearly: values['isYearly'] }
        }
        if (values.monday !== null && values.monday !== undefined) {
            result['settingFieldValues']['WorkTime'] = {
                monday: {
                    selected: values['monday'],
                    morning: {
                        startTime: values['start-morning-monday'],
                        endTime: values['end-morning-monday'],
                    },
                    afternoon: {
                        startTime: values['start-afternoon-monday'],
                        endTime: values['end-afternoon-monday'],
                    }
                },
                tuesday: {
                    selected: values['tuesday'],
                    morning: {
                        startTime: values['start-morning-tuesday'],
                        endTime: values['end-morning-tuesday'],
                    },
                    afternoon: {
                        startTime: values['start-afternoon-tuesday'],
                        endTime: values['end-afternoon-tuesday'],
                    }
                },
                wednesday: {
                    selected: values['wednesday'],
                    morning: {
                        startTime: values['start-morning-wednesday'],
                        endTime: values['end-morning-wednesday'],
                    },
                    afternoon: {
                        startTime: values['start-afternoon-wednesday'],
                        endTime: values['end-afternoon-wednesday'],
                    }
                },
                thursday: {
                    selected: values['thursday'],
                    morning: {
                        startTime: values['start-morning-thursday'],
                        endTime: values['end-morning-thursday'],
                    },
                    afternoon: {
                        startTime: values['start-afternoon-thursday'],
                        endTime: values['end-afternoon-thursday'],
                    }
                },
                friday: {
                    selected: values['friday'],
                    morning: {
                        startTime: values['start-morning-friday'],
                        endTime: values['end-morning-friday'],
                    },
                    afternoon: {
                        startTime: values['start-afternoon-friday'],
                        endTime: values['end-afternoon-friday'],
                    }
                },
                saturday: {
                    selected: values['saturday'],
                    morning: {
                        startTime: values['start-morning-saturday'],
                        endTime: values['end-morning-saturday'],
                    },
                    afternoon: {
                        startTime: values['start-afternoon-saturday'],
                        endTime: values['end-afternoon-saturday'],
                    }
                },
                sunday: {
                    selected: values['sunday'],
                    morning: {
                        startTime: values['start-morning-sunday'],
                        endTime: values['end-morning-sunday'],
                    },
                    afternoon: {
                        startTime: values['start-afternoon-sunday'],
                        endTime: values['end-afternoon-sunday'],
                    }
                },
            }
        }
        result['settingFieldValues']['ResultAbnormal'] = {
            bold: values.bold,
            block: values.block,
            italicized: values.italicized
        }

        onUpdateGeneralSetting(result, () => {
            setIsView(true)
            onGetGeneralSetting(company);
        });
    }

    const onChangeTreeViewItem = (item) => {
        if (item.id) {
            setGroup(item)
            setFieldOfGroup([])
        }
    }

    const onCancel = () => {
        setIsView(true)
        onGetGeneralSetting(company);
        onGetSettingField();
    }

    const onChangeCompany = (name, value, label, item) => {
        setIsView(true)
        setCompanyInfo(item[0])
        setCompany(value[0])
    }

    return (
        <React.Fragment>
            <Row >
                <Col sm="2" style={{ minWidth: '320px' }}>
                    <TreeViewBar
                        onChangeCompany={onChangeCompany}
                        onChangeTreeViewItem={onChangeTreeViewItem}
                        resource={RESOURCE}
                        groups={groups}
                    />
                </Col>
                <Col sm="8" className="card card-body ms-sm-3 " style={{ minHeight: '450px' }}>
                    {!isView &&
                        <AvForm
                            onValidSubmit={onValidSubmit}
                        >
                            <Row style={{
                                display: 'flex',
                                flexDirection: 'column',
                                minHeight: '52vh',
                                margin: '0',
                            }}>
                                <Row>
                                    <h5 className="p-3">
                                        {`${companyInfo.name}${companyInfo.manageCode ? ` - ${companyInfo.manageCode}` : ''}`}
                                        {/* {companyInfo?.name} - {companyInfo?.shortName} */}
                                    </h5>
                                </Row>
                                <Row>
                                    <DynamicFormForSetting
                                        items={fieldOfGroup}
                                        isEdit={true}
                                        resource={RESOURCE}
                                        needContact={true}
                                        company={company}
                                    />
                                </Row>
                            </Row>
                            <Row>
                                <Col>
                                    <CustomButtonGroup className="justify-content-center">
                                        <CustomButton
                                            text={t("common:Cancel")}
                                            type="button"
                                            onClick={onCancel}
                                            className="button-width"
                                        />
                                        <CustomButton
                                            text={t("common:Save")}
                                            type="submit"
                                            color="primary"
                                            className="save-user button-width"
                                            isEdit
                                        />
                                    </CustomButtonGroup>
                                </Col>
                            </Row>
                        </AvForm>}
                </Col>
            </Row>
        </React.Fragment>
    )
}

GeneralSetting.propTypes = {
    t: PropTypes.any,
    onUpdateGeneralSetting: PropTypes.any,
    onGetSettingField: PropTypes.any,
    onGetGeneralSetting: PropTypes.any,
    generalSetting: PropTypes.object,
}

const mapStateToProps = ({ generalSetting, common }) => {
    return ({
        companies: common.companies,
        generalSetting: generalSetting.generalSetting,
        settingField: generalSetting.settingField,
    })
}

const mapDispatchToProps = dispatch => ({
    onGetGeneralSetting: payload => dispatch(getGeneralSetting(payload)),
    onGetSettingField: payload => dispatch(getSettingField(payload)),
    onUpdateGeneralSetting: (payload, callBack) => dispatch(updateGeneralSetting(payload, callBack)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["generalSetting", "message"])(GeneralSetting)))
