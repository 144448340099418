import { CustomBootstrapTable, CustomButton, CustomModal } from "components/Common"
import {
  getAllOrganizations
} from "helpers/app-backend"
import { useEffect, useState } from "react"
import { ModalBody, ModalFooter } from "reactstrap"

//i18n
import { AvForm } from "availity-reactstrap-validation"
import FormSwitchForm from "components/Common/FormSwitchForm"
import IconStatus from "components/Common/IconStatus"
import { ModuleIds } from "constant"
import { convertDateFormat } from "helpers/utilities"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { updateResultProfileVendor, updateResultTestVendor } from "store/actions"
import PartnerSend from "./PartnerSend"
import TimeSend from "./TimeSend"
import CustomBootstrapInlineTable from "components/Common/CustomInlineTable/CustomBootstrapInlineTable"
import CustomBootstrapTableAsync from "components/Common/CustomTable/CustomBootstrapTableAsync"

const RESOURCE = ModuleIds.Test

const TestSend = ({
  modal,
  toggle,
  data = [],
  onUpdateResultTestVendor,
  onUpdateResultProfileVendor,
  testResult,
  // onCancel,
  t,
}) => {
  const title = t("Add test send")
  const userInfo = JSON.parse(localStorage.getItem("userInfo"))
  const [tests, setTests] = useState([])
  const [organizations, setOrganizations] = useState([])
  const [columnExpand, setColumnExpand] = useState([])

  const { family_name, given_name, name } = userInfo || {}
  const displayName =
    family_name && given_name ? `${family_name} ${given_name}` : name


  useEffect(() => {
    setTests([])
    fetchTest()
  }, [modal])

  useEffect(() => {
    if (organizations.length === 0) {
      getOrganizations()
    }
  }, [])

  const fetchTest = async () => {
    const newTest = data
    newTest.forEach(item => {
      item.sentUser = item?.sentUser || displayName
      item.sentUserId = item?.sentUserId || userInfo?.sub
      item.vendorName = organizations.find(x => x.organizationCode === item.vendorCode)?.name
      item.sentTime = item.sentTime || convertDateFormat(new Date(), "YYYY-MM-DD HH:mm:ss")
      item.isEnable = true
      if (item?.children && item.children.length > 0) {
        item.children.forEach(x => {
          x.sentUser = x?.sentUser || displayName
          x.sentUserId = x?.sentUserId || userInfo?.sub
          x.isEnable = true
          x.sentTime = item.sentTime || convertDateFormat(new Date(), "YYYY-MM-DD HH:mm:ss")
        })
      }
    })
    setTests([...newTest])
    let result = [];
    AddColumnExpand(newTest, result)
    setColumnExpand(result)
  }

  const UpdateExpandColumn = (code, row) => {
    let children = row['children'] || []
    if (children.length == 0)
      return;
    let result = columnExpand
    let ind = result.findIndex(x => x == code)
    if (ind < 0) {
      result.push(code)
    }
    else {
      result = result.filter(x => x != code)
    }
    setColumnExpand([...result])
  }

  const AddColumnExpand = (source, result) => {
    for (let index = 0; index < source.length; index++) {
      const element = source[index];
      if (element.children && element.children.length > 0) {
        result.push(element.code)
        AddColumnExpand(element.children, result)
      }
    }
  }

  const getOrganizations = async () => {
    const res = await getAllOrganizations({ page: 1, size: 0 })
    setOrganizations(res.data)
  }

  const onValidSubmit = async () => {
    const result = tests.filter(item => item.isChange && item.vendorCode !== null && !item.isDelete)
    const profileSent = result.filter(item => item.children)
    let testSent = []

    result.forEach(item => {
      if (item.children && item.children.length > 0) {
        testSent = [...testSent, ...item.children]
      } else {
        testSent.push(item)
      }
    })
    onUpdateResultTestVendor({ vendorList: testSent, resultId: testResult.id }, () => {
      onUpdateResultProfileVendor({ vendorList: profileSent, resultId: testResult.id }, () => {
        toggle(true)
      })
    })


  }

  const columns = [
    {
      dataField: "",
      text: "#",
      headerStyle: { width: "60px" },
      searchable: false,
      formatter: (cellContent, sample, index) => `${index + 1}`
    },
    {
      dataField: "testCode",
      text: t("Code"),
      style: { width: '100px' },
      searchable: true,
      formatter: (cellContent, item, index) => {
        return <span style={{ color: !item?.vendorCode && '#99caff' }} >{cellContent ?? item.code}</span>
      },
    },
    {
      dataField: "testName",
      text: t("Test"),
      searchable: true,
      style: { width: '300px' },
      formatter: (cellContent, item, index) => {
        return <div className="d-flex">
          {
            (item.children && item.children.length > 0) &&
            <div>
              <i className="fas fa-arrow-right"
                style={item.children && item.children.length > 0 ? { color: "#556ee6" } : {}}></i>
              <span className="px-2" style={{ fontWeight: 'bold' }}>{item.profileName}</span>
            </div>
          }
          <span style={{ color: !item?.vendorCode && '#99caff' }} >{item?.testName || item?.name}</span>
        </div>

      },
    },
    {
      dataField: "sent",
      text: t("Sent"),
      searchable: true,
      headerStyle: { width: '70px' },
      formatter: (cellContent, test, index) => {
        return test?.vendorCode && <IconStatus
          isRound={true}
          type={"Active"}
        />
      },
    },
    {
      dataField: "partnerSend",
      text: t("testPage:Partner Send"),
      style: { width: '250px' },
      formatter: (cellContent, item, index) => {
        return <PartnerSend
          partnerSend={{ vendorCode: item.vendorCode, name: item.vendorName || item.vendorCode }}
          onPartnerChange={(vendor) => {
            setColumnExpand([])
            tests[index].isChange = true
            tests[index].vendorCode = vendor?.organizationCode || vendor.value
            if (tests[index]?.children) {
              tests[index]?.children.forEach(x => {
                x.vendorCode = vendor?.organizationCode || vendor.value
                x.isChange = true
              })
            }
            // tests[index].testId = item.id
            setTests([...tests])
          }} />
      },
    },
    {
      dataField: "userSend",
      text: t("testPage:User Send"),
      style: { width: '150px' },
      formatter: (cellContent, item, index) => {
        return <span>{displayName}</span>
      },
    },
    {
      dataField: "sentTime",
      text: t("testPage:Sent Time"),
      formatter: (cellContent, item, index) => {
        return <TimeSend
          disabled={true}
          data={item.sentTime}
          onTimeSendChange={(value) => {
            tests[index].isChange = true
            tests[index].sentTime = value
            if (tests[index]?.children) {
              tests[index]?.children.forEach(x => {
                x.sentTime = value
                x.isChange = true
              })
            }
            // setTests([...tests])
          }}
        />

      },
    },
    {
      dataField: "action",
      text: "",
      isDummyField: true,
      style: { width: 50 },
      formatter: (cellContent, item, index) => {
        return <AvForm>
          <FormSwitchForm
            name="vendorCode"
            label=''
            value={true}
            onVendorChange={(value) => {
              tests[index].isEnable = value
              tests[index].isChange = true
              if (tests[index]?.children) {
                tests[index]?.children.forEach(x => {
                  x.isEnable = value
                  x.isChange = true
                })
              }
              setTests([...tests])
            }}
          />
        </AvForm>
      },
    },
  ]

  const columnChild = (rowIndex) => [
    {
      dataField: "",
      text: "#",
      headerStyle: { width: "60px" },
      searchable: false,
      formatter: (cellContent, sample, index) => `${rowIndex + 1}.${index + 1}`
    },
    {
      dataField: "testCode",
      text: t("Code"),
      style: { width: '100px' },
      searchable: true,
      formatter: (cellContent, item, index) => {
        return <span style={{ color: !item?.vendorCode && '#99caff' }} >{cellContent ?? item.code}</span>
      },
    },
    {
      dataField: "testName",
      text: t("Test"),
      searchable: true,
      style: { width: '300px' },
      formatter: (cellContent, item, index) => {
        return <span style={{ color: !item?.vendorCode && '#99caff' }} >{item?.testName || item?.name}</span>
      },
    },
    {
      dataField: "sent",
      text: t("Sent"),
      searchable: true,
      formatter: (cellContent, test, index) => {
        return test?.vendorCode && <IconStatus
          isRound={true}
          type={"Active"}
        />
      },
    },
    {
      dataField: "partnerSend",
      text: t("testPage:Partner Send"),
      style: { width: '150px' },
      // formatter: (cellContent, item, index) => {
      //   return <PartnerSend
      //     partnerSend={{ vendorCode: item.vendorCode, name: item.vendorName }}
      //     onPartnerChange={(vendor) => {
      //       tests[index].isChange = true
      //       tests[index].vendorCode = vendor?.organizationCode
      //       // tests[index].testId = item.id
      //       setTests([...tests])
      //     }} />
      // },
    },
    {
      dataField: "userSend",
      text: t("testPage:User Send"),
      style: { width: '150px' },
      // formatter: (cellContent, item, index) => {
      //   return <span>{displayName}</span>
      // },
    },
    {
      dataField: "sentTime",
      text: t("testPage:Sent Time"),
      formatter: (cellContent, item, index) => {
        return ''
      },
    },
    {
      dataField: "action",
      text: "",
      isDummyField: true,
      style: { width: 50 },
    },
  ]

  const getNonExpand = () => {
    let res = []
    res = tests.filter(x => !x.children || x.children.length == 0).map(x => x.id)
    res.push(-1)
    return res
  }

  const expandRow = {
    renderer: (row, rowIndex) => {
      return (
        <div className="table-expand-custom-header table-expand-custom">
          <CustomBootstrapInlineTable
            columns={columnChild(rowIndex)}
            data={row.children}
            isScrollable
            expandRow={expandRow}
            // loading={loading}
            isTableScrollX={true}
            searchText={""}
            keyField={"id"}
          // {...rest}
          />
        </div>
      )
    },
    nonExpandable: getNonExpand(),
    parentClassName: (isExpanded, row, rowIndex) => {
      return columnExpand.findIndex(x => x == row.id) >= 0 ? 'row-expanded' : 'row-noneexpanded'
    },
    expanded: columnExpand,
  }

  return (
    <CustomModal modal={modal} title={title} size="xl" onToggle={toggle}>
      <ModalBody style={{ height: 900 }}>
        <CustomBootstrapTableAsync
          columns={columns}
          showSelectRow={false}
          data={tests}
          expandRow={expandRow}
          expandable={false}
          isEnableLoadInfo={true}
          resource={RESOURCE}
          updatedTime={new Date()}
          keyField={"id"}
          onRefresh={() => {
            fetchTest()
          }}
          onRowClick={(e, row, rowIndex) => {
            UpdateExpandColumn(row.id, row)
          }}
          isEnableExport={false}
          paging={{ dataSize: tests.length, size: tests.length }}
        />
      </ModalBody>
      <ModalFooter>
        <CustomButton
          text={t("common:Cancel")}
          type="button"
          onClick={toggle}
          data-dismiss="modal"
          className="button-width"
        />

        <CustomButton
          text={t("common:Save")}
          type="submit"
          color="primary"
          onClick={onValidSubmit}
          className="save-user button-width"
          isEdit
        />
      </ModalFooter>
    </CustomModal>
  )
}

const mapStateToProps = ({ testResult }) => ({
  testResult: testResult.testResult,
})

const mapDispatchToProps = dispatch => ({
  onUpdateResultTestVendor: (test, callback) => dispatch(updateResultTestVendor(test, callback)),
  onUpdateResultProfileVendor: (test, callback) => dispatch(updateResultProfileVendor(test, callback))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["testPage", "common"])(TestSend))
