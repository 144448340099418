import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { permissionType } from "constant"
import { isEmptyArray } from "helpers/utilities"

class Check extends React.Component {
  // permission: ["Create", "Read", "Update", "Delete", "Export"]
  static propTypes = {
    resource: PropTypes.string.isRequired,
    permission: PropTypes.oneOf(Object.values(permissionType)),
    userPermissions: PropTypes.object,
    permissions: PropTypes.array, // any of
    exclusivePermissions: PropTypes.array, // except all
    fallback: PropTypes.node, // Prop for fallback content
  }

  static defaultProps = {
    userPermissions: {},
    permissions: [],
    exclusivePermissions: [],
    resource: ""
  }

  // Checks that user permission for resource is the same or greater than required
  allowed() {
    const {
      permission,
      userPermissions,
      permissions,
      resource,
      exclusivePermissions,
    } = this.props
    const userPermission = userPermissions[resource]?.permissions || []

    // check exclusive permissions
    const isExclusivePermissionExists = isEmptyArray(exclusivePermissions)
      ? true
      : userPermission.every(ai => !exclusivePermissions?.includes(ai))
      
    const isAllowed = isEmptyArray(permissions)
      ? userPermission.indexOf(permission) >= 0
      : userPermission.some(_userPermissions =>
        permissions?.includes(_userPermissions)
      )

    return isAllowed && isExclusivePermissionExists
  }

  render() {
    if (this.allowed()) return this.props.children
    return this.props.fallback || null
  }
}

const mapStateToProps = ({ Authorization }) => ({
  userPermissions: Authorization.permissions,
})

export default connect(mapStateToProps)(Check)
