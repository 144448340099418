


import { Check } from "components/Common"
import CustomButtonIcon from "components/Common/Button/CustomButtonIcon"
import { ModuleIds, TestRequest_State, permissionType } from "constant"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { withRouter } from "react-router-dom/cjs/react-router-dom.min"
import ReactTooltip from "react-tooltip"

const TestRequestAction = ({
    onClone,
    onCancel,
    t,
    index,
    param,
    history
}) => {
    const [loadingClone, setLoadingClone] = useState(false)
    const [loadingCancel, setLoadingCancel] = useState(false)
    const updating = useSelector(state => state.common.updating)
    const RESOURCE = ModuleIds.TestRequest
    useEffect(() => {
        if (!updating) {
            setLoadingClone(false)
            setLoadingCancel(false)
        }

    }, [updating])


    const setLoading = (name) => {
        switch (name) {
            case "Clone":
                setLoadingClone(true)
                setLoadingCancel(false)
                break;
            case "Cancel":
                setLoadingClone(false)
                setLoadingCancel(true)
                break;
        }
    }
    return (
        <div className="d-flex toolbar button-items text-end">
            <Check permission={permissionType.C} resource={RESOURCE}> 
            <CustomButtonIcon id={`copy-${index}`} color="btn btn-outline-primary btn-soft-copy"
                data-tip
                data-for={`copy-${index}`}
                isEdit={loadingClone}
                onClick={() => { onClone(param.id); setLoading('Clone') }}  >
                <i className="bx bx-copy-alt" />
            </CustomButtonIcon>
            <ReactTooltip place="top" id={`copy-${index}`} effect="solid">
                {t('common:Copy')}
            </ReactTooltip>
            </Check>
            <Check permission={permissionType.U} resource={RESOURCE}> 
            <CustomButtonIcon id={`edit-${index}`} color="btn btn-outline-info btn-soft-info"
                data-tip
                data-for={`edit-${index}`}
                onClick={e => {
                    history.push(`/TestRequest/${param.id}/edit?tab=1`)
                }}  >
                <i className="mdi mdi-pencil" />
            </CustomButtonIcon>
            </Check>
            <ReactTooltip place="top" id={`edit-${index}`} effect="solid" >
                {t('common:Edit')}
            </ReactTooltip>
            <Check permission={permissionType.D} resource={RESOURCE}> 
            <CustomButtonIcon id={`cancel-${index}`} color="btn btn-outline-danger btn-soft-danger"
                isEdit={loadingCancel}
                data-tip
                data-for={`cancel-${index}`}
                onClick={e => { onCancel(e, param); setLoading('Cancel') }}
                disabled={param?.state !== TestRequest_State.Draft && param?.state !== TestRequest_State.Submitted} >
                <i className="mdi mdi-delete-outline" />
            </CustomButtonIcon>
            <ReactTooltip place="top" id={`cancel-${index}`} effect="solid" >
                {t("common:Cancel")}
            </ReactTooltip>
            </Check>
        </div>
    )
}

TestRequestAction.propTypes = {

}

export default (withRouter(withTranslation(["common", "testRequestPage"])(TestRequestAction)))