import {
  GET_GENERAL_SETTING,
  GET_GENERAL_SETTING_FAIL,
  GET_GENERAL_SETTING_SUCCESS,
  GET_SETTING_FIELD,
  GET_SETTING_FIELD_FAIL,
  GET_SETTING_FIELD_SUCCESS,
  UPDATE_GENERAL_SETTING,
  UPDATE_GENERAL_SETTING_FAIL,
  UPDATE_GENERAL_SETTING_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
  generalSetting: [],
  generalSettingObject:{},
  settingField: [],
}

const GeneralSetting = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_GENERAL_SETTING:
      return {
        ...state,
      }
    case GET_GENERAL_SETTING_SUCCESS:
      const generalSetting = action.payload

      const generalSettingObject = action?.payload?.reduce((accumulator, currentValue) =>
        ({ ...accumulator, [currentValue.fieldName]: currentValue.fieldValue }),
        {})
      return {
        ...state,
        generalSetting: generalSetting,
        generalSettingObject: generalSettingObject,
      }

    case GET_GENERAL_SETTING_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_SETTING_FIELD:
      return {
        ...state,
      }
    case GET_SETTING_FIELD_SUCCESS:
      const settingField = action.payload
      return {
        ...state,
        settingField,
      }

    case GET_SETTING_FIELD_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_GENERAL_SETTING:
      return {
        ...state,
      }
    case UPDATE_GENERAL_SETTING_SUCCESS:
      return {
        ...state,
      }

    case UPDATE_GENERAL_SETTING_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default GeneralSetting
