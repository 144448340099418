import {
  ConfirmModal,
  ConfirmModal2,
  CustomButton,
  CustomModal,
  TitleAndTable,
  WarningModal
} from "components/Common"
import { ModuleIds, RuleManagementType, RuleTestType, columnsRule, columnsRuleAdditionalInfoCode, columnsRuleChild, columnsRuleChildAdditionalInfo, columnsRuleChildInstrument, columnsRuleChildRuleTime, columnsRuleCode, columnsRuleCodeText, columnsRuleResultTimeCode, parameterCode } from "constant"
import { CheckEmptyAllValueObject, CheckExistObjectInArray, compareSortArray, selectCheckboxHandler } from "helpers/utilities"
import React, { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { ModalBody, Row } from "reactstrap"
import { getCodesWithPage, getFullTestRuleSuccess, getMachineCodeMapping, getMachines } from "store/actions"
import {
  deleteRuleCustom,
  getAdditionalInfo, getFullTestRule, getResultTime, getRuleCustom, getRuleInstrumentAlert,
  getRuleInstrumentRange, getRuleNormalRange, getRulePatientRange, getRuleResultTime, getRuleRulerBase, getWorkflowCustom,
  importRuleManagement, resetSearchQuery, updateAdditionalInfo, updateResultTime, updateRuleManagement
} from "store/laboratory/ruleManagement/actions"
import { read, utils } from "xlsx"
import ButtonExport from "./ButtonExport"
import CustomRule from "./CustomRule"
import RuleTestModal from "./Modal/RuleTestModal"
import RuleManagementTable from "./RuleManagementTable"
import TreeViewBar_RulerManager from "./TreeViewBar"
import CustomSplitPane from "components/Common/CustomSplitPaneLeft"

const RESOURCE = ModuleIds.RuleManagement

let normalRangeData = []

let itemActiveGlobal = {}
const RuleManagements = ({
  tests,
  onGetTests,
  onGetTestsSuccess,
  onResetTestSearchQuery,
  onGetRuleNormalRange,
  onGetRulePatientRange,
  onGetRuleInstrumentRange,
  paging,
  t,
  onUpdateRuleManagement,
  updatedTime,
  machines,
  onGetMachines,
  onGetRules,
  onDeleteCustomRule,
  onGetCodes,
  onGetResultTime,
  onUpdateResultTime,
  onGetAdditionalInfo,
  onUpdateAdditionalInfo,
  onGetMappingCodes,
  onImportRuleManagement
}) => {
  const ref = useRef()
  const [keyInput, setKeyInput] = useState('childrenAlert')
  const [row, setRow] = useState([])
  const [rows, setRows] = useState([])
  const [rowRule, setRowRule] = useState([])
  const [fileName, setFileName] = useState(t("common:Upload"))
  const [dataSource, setDataSource] = useState([])
  const [dataExport, setDataExport] = useState([])
  const [warningModal, setWarningModal] = useState(false)
  const [warningModalEmpty, setWarningModalEmpty] = useState(false)
  const [warningDuplicate, setWarningDuplicate] = useState(false)
  const [duplicateRule, setDuplicateRule] = useState([])
  const [warningValueEmpty, setWarningValueEmpty] = useState(false)
  const [valueEmpty, setValueEmpty] = useState([])
  const [confirmModal, setConfirmModal] = useState(false)
  const [sapXep, setSapXep] = useState({})
  const [customRule, setCustomRule] = useState({})
  const [open, setOpen] = useState(false)
  const [warningDeleteRule, setWarningDeleteRule] = useState(false)
  const [openRuleTest, setOpenRuleTest] = useState(false)
  const [isUpload, setIsUpload] = useState(false)
  const [search, setSearch] = useState('')
  const [scale, setScale] = useState(false)

  const scaleScreen = () => {
    setScale(!scale)
  }
  useEffect(() => {
    onGetMachines({ status: 'Active', size: 0 })
  }, [])
  useEffect(() => {
    if (tests) {
      tests.sort((a, b) => {
        return b?.children?.length || b?.childrenInstrument?.length > 0 || b?.childrenAdditionalInfo?.length ? 1 : -1;
      })

      if (itemActive.Key == 2 && itemActive.parent.ID_Group == "1") {
        setDataSource(ConvertDataToChild(tests))
      }
      else if (itemActive.Key === 3 && itemActive.parent.ID_Group == "1") {
        setDataSource(ConvertDataToChildInstrument(tests))
      }
      else if (itemActive.Key === 5 && itemActive.parent.ID_Group == "1") {
        setDataSource(ConvertDataToChildResultTime(tests))
      }
      else if (itemActive.Key === 4 && itemActive.parent.ID_Group == "1") {
        setDataSource(ConvertDataToChildAdditionalInfo(tests))
      }
      else {
        setDataSource(ConvertDataToChildBasic(tests))
      }
    }
  }, [tests])

  useEffect(() => {
    if (itemActive.Key == 2 && itemActive.parent.ID_Group == "1") {
      setDataExport(ConvertDataToParent(dataSource))
    } else if (itemActive.Key == 3 && itemActive.parent.ID_Group == "1") {
      setDataExport(ConvertDataToParentInstrument(dataSource))
    }
    else if (itemActive.Key == 4 && itemActive.parent.ID_Group == "1") {
      setDataExport(ConvertDataToChildAdditionalInfoExport(dataSource))
    }
    else if (itemActive.Key == 5 && itemActive.parent.ID_Group == "1") {
      setDataExport(ConvertDataToChildResultTimeExport(dataSource))
    }
    else {
      setDataExport(ConvertDataToNormalRange(dataSource))
    }
  }, [dataSource])

  const ConvertDataToNormalRange = (vals) => {
    let res = []
    for (let index = 0; index < vals.length; index++) {
      const row = vals[index];
      res.push({
        "id": row.id,
        "testCode": row.testCode,
        "testName": row.testName,
        "selected": row.selected,
        "unit": row.unit,
        "normalRange": row.normalRange,
        //"expression": row.expression,
        "defaultResult": row.defaultResult,
        "lowerLimit": row.lowerLimit,
        "upperLimit": row.upperLimit,
        "lowerAlert": row.lowerAlert,
        "upperAlert": row.upperAlert,
        "enable": !!row.enable
      })
    }
    return res;
  }

  const ConvertDataToParent = (vals) => {
    let res = []
    for (let index = 0; index < vals.length; index++) {
      const row = vals[index];
      if (row.children && row.children.length > 0) {
        for (let indexChild = 0; indexChild < row.children.length; indexChild++) {
          const element = row.children[indexChild];
          res.push({
            "id": row.id,
            "selected": row.selected,
            "testCode": row.testCode,
            "testName": row.testName,
            "age": element.age,
            "ageType": element.ageType,
            "gender": element.gender,
            "unit": element.unit || row.unit,
            "normalRange": element.normalRange || row.normalRange,
            //"expression": element.expression || row.expression,
            "defaultResult": element.defaultResult || row.defaultResult,
            "lowerLimit": element.lowerLimit || row.lowerLimit,
            "upperLimit": element.upperLimit || row.upperLimit,
            "lowerAlert": element.lowerAlert || row.lowerAlert,
            "upperAlert": element.upperAlert || row.upperAlert,
            "enable": !!element.enable || !!row.enable,

          })
        }
      }
      else {
        res.push({
          "id": row.id,
          "selected": row.selected,
          "testCode": row.testCode,
          "testName": row.testName,
          "age": '',
          "ageType": '',
          "gender": '',
          "unit": row.unit,
          "normalRange": row.normalRange,
          //"expression": row.expression,
          "defaultResult": row.defaultResult,
          "lowerLimit": row.lowerLimit,
          "upperLimit": row.upperLimit,
          "lowerAlert": row.lowerAlert,
          "upperAlert": row.upperAlert,
          "enable": !!row.enable,
        })
      }
    }
    return res;
  }

  const ConvertDataToParentInstrument = (vals) => {
    let res = []
    for (let index = 0; index < vals.length; index++) {
      const row = vals[index];
      if (row.childrenInstrument && row.childrenInstrument.length > 0) {
        for (let indexChild = 0; indexChild < row.childrenInstrument.length; indexChild++) {
          const element = row.childrenInstrument[indexChild];
          res.push({
            "id": row.id,
            "selected": row.selected,
            "testCode": row.testCode,
            "testName": row.testName,
            "age": element.age,
            "ageType": element.ageType,
            "gender": element.gender,
            "unit": row.unit,
            "normalRange": element.normalRange || row.normalRange,
            //"expression": element.expression || row.expression,
            "defaultResult": element.defaultResult || row.defaultResult,
            "lowerLimit": element.lowerLimit || row.lowerLimit,
            "upperLimit": element.upperLimit || row.upperLimit,
            "lowerAlert": element.lowerAlert || row.lowerAlert,
            "upperAlert": element.upperAlert || row.upperAlert,
            "enable": !!element.enable || !!row.enable,
          })
        }
      }
      else {
        res.push({
          "id": row.id,
          "testCode": row.testCode,
          "testName": row.testName,
          "selected": row.selected,
          "unit": row.unit,
          "normalRange": row.normalRange,
          //"expression": row.expression,
          "defaultResult": row.defaultResult,
          "lowerLimit": row.lowerLimit,
          "upperLimit": row.upperLimit,
          "lowerAlert": row.lowerAlert,
          "upperAlert": row.upperAlert,
          "enable": !!row.enable,
          // "protocol": '',
          // "instrument": '',
          "age": row.age,
          "ageType": row.ageType,
          "gender": row.gender,
        })
      }
    }
    return res;
  }
  const ConvertDataToChildBasic = (vals) => {
    let res = []
    for (let index = 0; index < vals.length; index++) {
      const row = vals[index];
      const ind = res.findIndex(x => x.testCode == row.testCode)
      if (ind < 0) {
        res.push({
          "id": row.id,
          "testCode": row.testCode,
          "testName": row.testName,
          "selected": row.selected,
          "unit": row.unit,
          "normalRange": row.normalRange,
          //"expression": row.expression,
          "defaultResult": row.defaultResult,
          "lowerLimit": row.lowerLimit,
          "upperLimit": row.upperLimit,
          "lowerAlert": row.lowerAlert,
          "upperAlert": row.upperAlert,
          "enable": !!row.enable,
          "children": row.children || [],
          "childrenInstrument": row.childrenInstrument || []
        })
      }
    }
    return res;
  }

  const ConvertDataToChild = (vals) => {
    let res = []
    for (let index = 0; index < vals.length; index++) {
      const row = vals[index];
      const ind = res.findIndex(x => x.testCode == row.testCode)
      if (ind >= 0) {
        res[ind].children.push({
          "id": new Date().getTime(),
          "parentId": res[ind].parentId,
          "unit": row.unit,
          "normalRange": row.normalRange,
          //"expression": row.expression,
          "defaultResult": row.defaultResult,
          "lowerLimit": row.lowerLimit,
          "upperLimit": row.upperLimit,
          "lowerAlert": row.lowerAlert,
          "upperAlert": row.upperAlert,
          "enable": !!row.enable,
          "age": row.age,
          "ageType": row.ageType,
          "gender": row.gender,
        })
      }
      else {
        const child = {
          "age": row.age,
          "ageType": row.ageType,
          "gender": row.gender,
        }
        let check = CheckEmptyAllValueObject(child)
        let val = {
          "id": row.id,
          "testCode": row.testCode,
          "testName": row.testName,
          "selected": row.selected,
          "unit": row.unit,
          "normalRange": row.normalRange,
          //"expression": row.expression,
          "defaultResult": row.defaultResult,
          "lowerLimit": row.lowerLimit,
          "upperLimit": row.upperLimit,
          "lowerAlert": row.lowerAlert,
          "upperAlert": row.upperAlert,
          "enable": !!row.enable,
          "children": row.children || [],
        }
        if (check == false)
          val.children.push({
            "id": new Date().getTime(),
            "parentId": row.id,
            "unit": row.unit,
            "normalRange": row.normalRange,
            //"expression": row.expression,
            "defaultResult": row.defaultResult,
            "lowerLimit": row.lowerLimit,
            "upperLimit": row.upperLimit,
            "lowerAlert": row.lowerAlert,
            "upperAlert": row.upperAlert,
            "enable": !!row.enable,
            "age": row.age,
            "ageType": row.ageType,
            "gender": row.gender,
          })
        res.push(val)
      }
    }
    return res;
  }

  const ConvertDataToChildAdditionalInfo = (vals) => {
    let res = []
    for (let index = 0; index < vals.length; index++) {
      const row = vals[index];
      const ind = res.findIndex(x => x.sampleType === row.code)
      if (ind >= 0) {
        res[ind].childrenAdditionalInfo.push({
          "id": new Date().getTime(),
          "parentId": res[ind].parentId,
          "sampleType": row.sampleType,
          "subCategory": row.subCategory,
          "testCode": row.testCode,
          "subId": row.subId,
          "color": row.color,
          "labels": row.labels,
          "enable": !!row.enable,
        })
      }
      else {
        const child = {
          "subCategory": row.subCategory,
          "testCode": row.testCode,
        }
        let check = CheckEmptyAllValueObject(child)
        let val = {
          "id": row.id,
          "sampleType": row.code || row.sampleType,
          "sampleTypeName": row.message || row.sampleTypeName,
          "selected": row.selected,
          "childrenAdditionalInfo": row.childrenAdditionalInfo || [],
        }
        if (check == false)
          val.childrenAdditionalInfo.push({
            "id": new Date().getTime(),
            "parentId": row.id,
            "sampleType": row.sampleType,
            "subCategory": row.subCategory,
            "testCode": row.testCode,
            "subId": row.subId,
            "color": row.color,
            "labels": row.labels,
            "enable": !!row.enable,
          })
        res.push(val)
      }
    }
    return res;
  }

  const ConvertDataToChildResultTime = (vals) => {
    let res = []
    for (let index = 0; index < vals.length; index++) {
      const row = vals[index];
      const ind = res.findIndex(x => x.subCategory === row.code)
      if (ind >= 0) {
        res[ind].childrenResultTime.push({
          "id": new Date().getTime(),
          "parentId": res[ind].parentId,
          "resultTime": row.resultTime,
          "timeCollection": row.timeCollection,
          "testCode": row.testCode,
          "profileCode": row.profileCode,
          "serviceType": row.serviceType,
          "inPatient": row.inPatient,
          "enable": !!row.enable,
        })
      }
      else {
        const child = {
          "timeCollection": row.timeCollection,
          "testCode": row.testCode,
          "profileCode": row.profileCode,
          "serviceType": row.serviceType,
          "inPatient": row.inPatient,
        }
        let check = CheckEmptyAllValueObject(child)
        let val = {
          "id": row.id,
          "subCategory": row.code || row.subCategory,
          "subCategoryName": row.message || row.subCategoryName,
          "selected": row.selected,
          "childrenResultTime": row.childrenResultTime || [],
        }
        if (check == false)
          val.childrenResultTime.push({
            "id": new Date().getTime(),
            "parentId": row.id,
            "timeCollection": row.timeCollection,
            "profileCode": row.profileCode,
            "serviceType": row.serviceType,
            "resultTime": row.resultTime,
            "enable": !!row.enable,
            "testCode": row.testCode,
            "inPatient": row.inPatient,
          })
        res.push(val)
      }
    }
    return res;
  }

  const ConvertDataToChildResultTimeExport = (vals) => {
    let res = []
    for (let index = 0; index < vals.length; index++) {
      const row = vals[index];
      if (row.childrenResultTime && row.childrenResultTime.length > 0) {
        for (let indexChild = 0; indexChild < row.childrenResultTime.length; indexChild++) {
          const element = row.childrenResultTime[indexChild];
          res.push({
            "id": row.id,
            "subCategory": row.subCategory,
            "subCategoryName": row.subCategoryName,
            "selected": row.selected,
            "resultTime": element.resultTime || row.resultTime,
            "timeCollection": element.timeCollection || row.timeCollection,
            "testCode": element.testCode || row.testCode,
            "profileCode": element.profileCode || row.profileCode,
            "serviceType": element.serviceType || row.serviceType,
            "inPatient": element.inPatient || row.inPatient,
            "enable": element.enable || row.enable,
          })
        }
      }
      else {
        res.push({
          "id": row.id,
          "subCategory": row.subCategory,
          "subCategoryName": row.subCategoryName,
          "selected": row.selected,
          "resultTime": row.resultTime,
          "timeCollection": row.timeCollection,
          "testCode": row.testCode,
          "profileCode": row.profileCode,
          "inPatient": row.inPatient,
          "serviceType": row.serviceType,
          "enable": !!row.enable,
        })
      }
    }
    return res;
  }

  const ConvertDataToChildAdditionalInfoExport = (vals) => {
    let res = []
    for (let index = 0; index < vals.length; index++) {
      const row = vals[index];
      if (row.childrenAdditionalInfo && row.childrenAdditionalInfo.length > 0) {
        for (let indexChild = 0; indexChild < row.childrenAdditionalInfo.length; indexChild++) {
          const element = row.childrenAdditionalInfo[indexChild];
          res.push({
            "id": row.id,
            "sampleType": row.sampleType,
            "sampleTypeName": row.sampleTypeName,
            "selected": row.selected,
            "subCategory": element.subCategory || row.subCategory,
            "testCode": element.testCode || row.testCode,
            "subId": element.subId || row.subId,
            "color": element.color || row.color,
            "labels": element.labels || row.labels,
            "enable": element.enable || row.enable,
          })
        }
      }
      else {
        res.push({
          "id": row.id,
          "sampleType": row.sampleType,
          "sampleTypeName": row.sampleTypeName,
          "selected": row.selected,
          "subCategory": row.subCategory,
          "testCode": row.testCode,
          "subId": row.subId,
          "color": row.color,
          "labels": row.labels,
          "enable": !!row.enable,
        })
      }
    }
    return res;
  }

  const ConvertDataToChildInstrument = (vals) => {
    let res = []
    for (let index = 0; index < vals.length; index++) {
      const row = vals[index];
      if (row.childrenInstrument?.length > 0) {
        row.childrenInstrument.forEach((instrument) => {
          instrument.parentId = row.id
        })
      }
      const ind = res.findIndex(x => x.testCode == row.testCode)
      if (ind >= 0) {

        res[ind].childrenInstrument.push({
          "id": new Date().getTime(),
          "parentId": res[ind].parentId,
          "instrument": itemActive.Name,
          "unit": row.unit,
          "normalRange": row.normalRange,
          //"expression": row.expression,
          "defaultResult": row.defaultResult,
          "lowerLimit": row.lowerLimit,
          "upperLimit": row.upperLimit,
          "lowerAlert": row.lowerAlert,
          "upperAlert": row.upperAlert,
          "enable": !!row.enable,
          "age": row.age,
          "ageType": row.ageType,
          "gender": row.gender,
        })
      }
      else {
        const child = {
          "age": row.age,
          "ageType": row.ageType,
          "gender": row.gender,
        }
        let check = CheckEmptyAllValueObject(child)
        let val = {
          "id": row.id,
          "instrument": itemActive.Name,
          "testCode": row.testCode,
          "unit": row.unit,
          "testName": row.testName,
          "selected": row.selected,
          "childrenInstrument": row.childrenInstrument || [],
        }
        if (check == false) {
          val.childrenInstrument.push({
            "id": new Date().getTime(),
            "parentId": row.id,
            "instrument": row.instrument,
            "unit": row.unit,
            "normalRange": row.normalRange,
            "defaultResult": row.defaultResult,
            "lowerLimit": row.lowerLimit,
            "upperLimit": row.upperLimit,
            "lowerAlert": row.lowerAlert,
            "upperAlert": row.upperAlert,
            "enable": !!row.enable,
            "age": row.age,
            "ageType": row.ageType,
            "gender": row.gender,
          })
        }
        res.push(val)
      }
    }

    return res;

  }

  const fetchTests = () => {
    if (itemActive.Key === 5 && itemActive.parent?.ID_Group == "1") {
      const sort = 'sequence:asc'
      setTimeout(async () => {
        onGetCodes(parameterCode.TEST_CATEGORIES, search, sort, '', true, () => {
          onGetResultTime()
        })
      }, 2000)
    } else if (itemActive.Key === 4 && itemActive.parent.ID_Group == "1") {
      const sort = 'sequence:asc'
      setTimeout(async () => {
        onGetCodes(parameterCode.SAMPLE_TYPES, search, sort, '', true, () => {
          onGetAdditionalInfo()
        })
      }, 2000)
    } else if (itemActive.Key == 3 && itemActive.parent?.ID_Group == "1") {
      onGetMappingCodes({ instrumentId: itemActive.machineId, query: { search, inUse: true } }, () => {
        onGetRuleInstrumentRange(itemActive.machineId);
      })
    } else if (itemActive.Key == 1) {
      onGetTests({ search, InUse: true, sort: 'DisplayOrder' }, () => {
        onGetRuleNormalRange({})
      })
    }
    else if (itemActive.Key == 2) {
      onGetTests({ search, InUse: true, sort: 'DisplayOrder' }, () => {
        onGetRulePatientRange()
      })
    }
  }

  const onSearch = searchText => {
    setSearch(searchText || '')

    if (itemActive.Key === 5 && itemActive.parent?.ID_Group == "1") {
      const sort = 'sequence:asc'
      onGetCodes(34, sort, searchText, true, () => {
        onGetResultTime();
      })
    }
    else if (itemActive.Key === 4 && itemActive.parent?.ID_Group == "1") {
      const sort = 'sequence:asc'
      onGetCodes(32, sort, searchText, true, () => {
        onGetAdditionalInfo();
      })

    } else if (itemActive.Key == 3 && itemActive.parent.ID_Group == "1") {
      onGetMappingCodes({ instrumentId: itemActive.machineId, query: { search: searchText, inUse: true } }, () => {
        onGetRuleInstrumentRange(itemActive.machineId);
      })
    }
    else {
      onGetTests({ search: searchText, isSearch: true, InUse: true, sort: 'DisplayOrder' }, () => {
        if (itemActive.Key == 2 && itemActive.parent.ID_Group == "1") {
          onGetRuleNormalRange({}, () => {
            if (itemActive.Key == 2 && itemActive.parent?.ID_Group == "1") {
              onGetRulePatientRange()
            }
          })
        }
        else {
          onGetRuleNormalRange()
        }
      })
    }
  }

  const onRefreshHandler = () => {
    fetchTests()
  }
  useEffect(() => {
    onResetTestSearchQuery()
  }, [])

  const onSelectCheckbox = (row, isSelected, isAddRule) => {
    if (isAddRule) {
      setRows((currentData) => [...currentData, row])
      setRow(row)
    } else {
      const { rowsState, currentRow } = selectCheckboxHandler(
        rows,
        row,
        isSelected
      )
      setRows(rowsState)
      setRow(currentRow)
    }
  }

  const onSelectAllCheckbox = (rows, isSelected) => {
    if (isSelected !== undefined) {
      if (rows.length < 1) {
        setRow({})
        setRows([])
      }
      else {
        setRows(dataSource)
        setRow(dataSource[dataSource.length - 1])
      }
    }
  }

  const handleImport = e => {
    const files = e.target.files
    if (files.length) {
      let rowChoose = []
      const file = files[0]
      const reader = new FileReader()
      reader.onload = event => {
        const wb = read(event.target.result)
        const sheets = wb.SheetNames

        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]])
          let tmp = dataSource
          let rowChecked = [];
          rows.forEach(item => {
            if (item.selected == 1) {
              rowChoose.push(item)
              const ind = tmp.findIndex(x => x.testCode == item.testCode)
              const indResultTime = tmp.findIndex(x => x.subCategory == item.subCategory)
              const indAdditionalInfo = tmp.findIndex(x => x.sampleType == item.sampleType)
              if (itemActive.Key === 1 || itemActive.Key === 2 || itemActive.Key === 3)
                item.id = tmp[ind]?.id
              if (itemActive.Key === 5) {
                item.id = tmp[indResultTime].id
              }
              if (itemActive.Key === 4) {
                item.id = tmp[indAdditionalInfo].id
              }
              if (itemActive.Key === 5) {
                if (indResultTime >= 0) {
                  tmp[indResultTime].code = item.subCategory
                  tmp[indResultTime].message = item.subCategoryName
                  if (rowChecked.findIndex(a => a.subCategory === item.subCategory) >= 0) {
                    tmp[indResultTime].childrenResultTime.push({
                      id: new Date().getTime(),
                      subCategory: item.subCategory,
                      timeCollection: item[columnsRuleResultTimeCode['timeCollection']] || '',
                      testCode: item[columnsRuleResultTimeCode['testCode']] || '',
                      profileCode: item[columnsRuleResultTimeCode['profileCode']] || '',
                      serviceType: item[columnsRuleResultTimeCode['serviceType']] || '',
                      inPatient: item[columnsRuleResultTimeCode['inPatient']] || '',
                      parentId: tmp[indResultTime].id,
                      enable: !!item[columnsRuleResultTimeCode['enable']],
                      resultTime: item[columnsRuleResultTimeCode['resultTime']] || '',
                    })
                  } else {
                    rowChecked.push(item)
                    tmp[indResultTime].childrenResultTime = [{
                      id: new Date().getTime(),
                      subCategory: item.subCategory,
                      timeCollection: item[columnsRuleResultTimeCode['timeCollection']] || '',
                      testCode: item[columnsRuleResultTimeCode['testCode']] || '',
                      profileCode: item[columnsRuleResultTimeCode['profileCode']] || '',
                      serviceType: item[columnsRuleResultTimeCode['serviceType']] || '',
                      inPatient: item[columnsRuleResultTimeCode['inPatient']] || '',
                      parentId: tmp[indResultTime].id,
                      enable: !!item[columnsRuleResultTimeCode['enable']],
                      resultTime: item[columnsRuleResultTimeCode['resultTime']] || '',
                    }]
                  }
                }
              }

              if (itemActive.Key === 4) {
                if (indAdditionalInfo >= 0) {
                  tmp[indAdditionalInfo].code = item.sampleType
                  tmp[indAdditionalInfo].message = item.sampleTypeName
                  if (rowChecked.findIndex(a => a.sampleType === item.sampleType) >= 0) {
                    tmp[indAdditionalInfo].childrenAdditionalInfo.push({
                      id: new Date().getTime(),
                      sampleType: item.sampleType,
                      subCategory: item[columnsRuleAdditionalInfoCode['subCategory']] || '',
                      testCode: item[columnsRuleAdditionalInfoCode['testCode']] || '',
                      subId: item[columnsRuleAdditionalInfoCode['subId']] || '',
                      color: item[columnsRuleAdditionalInfoCode['color']] || '',
                      labels: item[columnsRuleAdditionalInfoCode['labels']] || '',
                      parentId: tmp[indAdditionalInfo].id,
                      enable: !!item[columnsRuleAdditionalInfoCode['enable']],
                    })
                  } else {
                    rowChecked.push(item)
                    tmp[indAdditionalInfo].childrenAdditionalInfo = [{
                      id: new Date().getTime(),
                      sampleType: item.sampleType,
                      subCategory: item[columnsRuleAdditionalInfoCode['subCategory']] || '',
                      testCode: item[columnsRuleAdditionalInfoCode['testCode']] || '',
                      subId: item[columnsRuleAdditionalInfoCode['subId']] || '',
                      color: item[columnsRuleAdditionalInfoCode['color']] || '',
                      labels: item[columnsRuleAdditionalInfoCode['labels']] || '',
                      parentId: tmp[indAdditionalInfo].id,
                      enable: !!item[columnsRuleAdditionalInfoCode['enable']],
                    }]
                  }
                }
              }

              if (itemActive.Key !== 5 && itemActive.Key !== 4) {
                if (ind >= 0) {
                  if (rowChecked.findIndex(a => a.testCode == item.testCode) >= 0) {
                    //đã thêm testcode này
                    if (itemActiveGlobal.Key == 2 && itemActiveGlobal.parent.ID_Group == "1") {
                      tmp[ind].children.push({
                        id: new Date().getTime(),
                        testCode: item.testCode,
                        unit: item[columnsRuleCodeText['unit']] || '',
                        normalRange: item[columnsRuleCodeText['normalRange']] || '',
                        // expression: item[columnsRuleCodeText['expression']] || '',
                        defaultResult: `${item[columnsRuleCodeText['defaultResult']]}` || '',
                        lowerLimit: `${item[columnsRuleCodeText['lowerLimit']]}` || '',
                        upperLimit: `${item[columnsRuleCodeText['upperLimit']]}` || '',
                        lowerAlert: `${item[columnsRuleCodeText['lowerAlert']]}` || '',
                        upperAlert: `${item[columnsRuleCodeText['upperAlert']]}` || '',
                        enable: !!item[columnsRuleCodeText['enable']],
                        age: item[columnsRuleCodeText['age']] || '',
                        ageType: item[columnsRuleCodeText['ageType']] || '',
                        gender: item[columnsRuleCodeText['gender']] || '',
                        parentId: tmp[ind].id
                      })
                    }
                    else if (itemActiveGlobal.Key == 3 && itemActiveGlobal.parent.ID_Group == "1") {
                      tmp[ind].childrenInstrument.push({
                        id: new Date().getTime(),
                        testCode: item.testCode,
                        unit: item[columnsRuleCodeText['unit']] || '',
                        normalRange: item[columnsRuleCodeText['normalRange']] || '',
                        // expression: item[columnsRuleCodeText['expression']] || '',
                        defaultResult: `${item[columnsRuleCodeText['defaultResult']]}` || '',
                        lowerLimit: `${item[columnsRuleCodeText['lowerLimit']]}` || '',
                        upperLimit: `${item[columnsRuleCodeText['upperLimit']]}` || '',
                        lowerAlert: `${item[columnsRuleCodeText['lowerAlert']]}` || '',
                        upperAlert: `${item[columnsRuleCodeText['upperAlert']]}` || '',
                        enable: !!item[columnsRuleCodeText['enable']],
                        age: item[columnsRuleCodeText['age']] || '',
                        ageType: item[columnsRuleCodeText['ageType']] || '',
                        gender: item[columnsRuleCodeText['gender']] || '',
                        parentId: tmp[ind].id
                      })
                    }
                    else if ((itemActiveGlobal.Key == 6 && itemActiveGlobal.parent.ID_Group == "1") || (itemActiveGlobal.Key == 4 && itemActiveGlobal.parent.ID_Group == "1")) {
                      tmp[ind][keyInput].push({
                        id: new Date().getTime(),
                        testCode: item.testCode,
                        unit: item['unit'] || '',
                        age: item['age'] || '',
                        ageType: item['ageType'] || '',
                        gender: item['gender'] || '',
                        ruleType: item['ruleType'] || '',
                        value: item['value'] || '',
                        operatorType: item['operatorType'] || '',
                        ruleAction: item['ruleAction'] || '',
                        color: item['color'] || '',
                        parentId: tmp[ind].id
                      })
                    }
                  }
                  else {
                    //chưa có testcode này
                    rowChecked.push(item)
                    //check rule status
                    if (itemActiveGlobal.Key == 2 && itemActiveGlobal.parent.ID_Group == "1") {
                      // for (const [key, value] of Object.entries(tmp[ind])) {
                      //   //tmp[ind].unit=item
                      //   tmp[ind][columnsRuleCode[key]] = item[columnsRuleCodeText[columnsRuleCode[key]]]
                      // }
                      tmp[ind].children = [{
                        id: new Date().getTime(),
                        testCode: item.testCode,
                        unit: item[columnsRuleCodeText['unit']] || '',
                        normalRange: item[columnsRuleCodeText['normalRange']] || '',
                        //expression: item[columnsRuleCodeText['expression']] || '',
                        defaultResult: `${item[columnsRuleCodeText['defaultResult']]}` || '',
                        lowerLimit: `${item[columnsRuleCodeText['lowerLimit']]}` || '',
                        upperLimit: `${item[columnsRuleCodeText['upperLimit']]}` || '',
                        lowerAlert: `${item[columnsRuleCodeText['lowerAlert']]}` || '',
                        upperAlert: `${item[columnsRuleCodeText['upperAlert']]}` || '',
                        enable: !!item[columnsRuleCodeText['enable']],
                        age: item[columnsRuleCodeText['age']] || '',
                        ageType: item[columnsRuleCodeText['ageType']] || '',
                        gender: item[columnsRuleCodeText['gender']] || '',
                        parentId: tmp[ind].id
                      }]
                    }
                    else if (itemActiveGlobal.Key == 3 && itemActiveGlobal.parent.ID_Group == "1") {
                      // for (const [key, value] of Object.entries(tmp[ind])) {
                      //   //tmp[ind].unit=item
                      //   tmp[ind][columnsRuleCode[key]] = item[columnsRuleCodeText[columnsRuleCode[key]]]
                      // }
                      tmp[ind].childrenInstrument = [{
                        id: new Date().getTime(),
                        testCode: item.testCode,
                        unit: item[columnsRuleCodeText['unit']] || '',
                        normalRange: item[columnsRuleCodeText['normalRange']] || '',
                        //expression: item[columnsRuleCodeText['expression']] || '',
                        defaultResult: `${item[columnsRuleCodeText['defaultResult']]}` || '',
                        lowerLimit: `${item[columnsRuleCodeText['lowerLimit']]}` || '',
                        upperLimit: `${item[columnsRuleCodeText['upperLimit']]}` || '',
                        lowerAlert: `${item[columnsRuleCodeText['lowerAlert']]}` || '',
                        upperAlert: `${item[columnsRuleCodeText['upperAlert']]}` || '',
                        enable: !!item[columnsRuleCodeText['enable']],
                        // protocol: item[columnsRuleCodeText['protocol']] || '',
                        // instrument: item[columnsRuleCodeText['instrument']] || '',
                        age: item[columnsRuleCodeText['age']] || '',
                        ageType: item[columnsRuleCodeText['ageType']] || '',
                        gender: item[columnsRuleCodeText['gender']] || '',
                        parentId: tmp[ind].id
                      }]
                    }
                    else if ((itemActiveGlobal.Key == 6 && itemActiveGlobal.parent.ID_Group == "1") || (itemActiveGlobal.Key == 4 && itemActiveGlobal.parent.ID_Group == "1")) {
                      tmp[ind][keyInput] = [{
                        id: new Date().getTime(),
                        testCode: item.testCode,
                        unit: item['unit'] || '',
                        age: item['age'] || '',
                        ageType: item['ageType'] || '',
                        gender: item['gender'] || '',
                        ruleType: item['ruleType'] || '',
                        value: item['value'] || '',
                        operatorType: item['operatorType'] || '',
                        ruleAction: item['ruleAction'] || '',
                        color: item['color'] || '',
                        parentId: tmp[ind].id
                      }]
                    }
                    else {
                      for (const [key, value] of Object.entries(tmp[ind])) {
                        //tmp[ind].unit=item
                        tmp[ind][key] = `${item[columnsRuleCodeText[key]]}`
                      }
                    }
                  }
                }
              }
            }
          })

          if (itemActiveGlobal.Key == 6 && itemActiveGlobal.parent.ID_Group == "1") {
            tmp.forEach(element => {
              if (element[keyInput] && element[keyInput].length > 0) {
                element[keyInput] = element[keyInput].filter(x => !CheckEmptyAllValueObject({
                  "unit": x.unit,
                  "age": x.age,
                  "gender": x.gender,
                  "ruleType": x.ruleType,
                  "value": x.value,
                  "operatorType": x.operatorType,
                  "ruleAction": x.ruleAction,
                  "color": x.color,
                }))
              }
            });
          }
          onGetTestsSuccess(tmp)
          setRows(rowChoose)
        }
      }
      reader.readAsArrayBuffer(file)
    }
  }
  const onSortHandler = (a, b) => {
    const filter = { order: b, dataField: a }
    if (
      sapXep.dataField === filter.dataField &&
      filter.order === sapXep.order
    )
      return;

    const newData = JSON.parse(JSON.stringify(dataSource))
      .sort((a, b) => {
        return compareSortArray(a[filter.dataField], b[filter.dataField], filter.order)
      })
      .map((_item, _idx) => {
        _item.displayOrder = _idx
        return _item
      })
    setSapXep(filter)
    setDataSource(newData)
  }
  const [itemActive, setItemActive] = useState({
    Key: 1, Name: 'R_Normal Range', Result: 234,
    parent: { ID_Group: '1', GroupName: t("common:Rule management") }
  })
  const getColumnExport = () => {
    if (itemActive.Key == 2 && itemActive.parent.ID_Group == "1")
      return columnsRuleChild
    else if (itemActive.Key == 3 && itemActive.parent.ID_Group == "1")
      return columnsRuleChildInstrument
    else if (itemActive.Key === 4 && itemActive.parent.ID_Group == "1")
      return columnsRuleChildAdditionalInfo
    else if (itemActive.Key === 5 && itemActive.parent.ID_Group == "1")
      return columnsRuleChildRuleTime
    else return columnsRule
  }

  const getDataExport = () => {
    if (itemActive.Key == 2 && itemActive.parent.ID_Group == "1") {
      let dt = dataExport.map(row => {
        let res = {}
        columnsRuleChild.forEach(element => {
          res[element] = row[columnsRuleCode[element]]
        });
        return res
      })
      return dt
    } else if (itemActive.Key == 3 && itemActive.parent.ID_Group == "1") {
      let dt = dataExport.map(row => {
        let res = {}
        columnsRuleChildInstrument.forEach(element => {
          res[element] = row[columnsRuleCode[element]]
        });
        return res
      })
      return dt
    }
    else if (itemActive.Key === 5 && itemActive.parent.ID_Group == "1") {
      let dt = dataExport.map(row => {
        let res = {}
        columnsRuleChildRuleTime.forEach(element => {
          res[element] = row[columnsRuleResultTimeCode[element]]
        });
        return res
      })
      return dt
    }
    else if (itemActive.Key === 4 && itemActive.parent.ID_Group == "1") {
      let dt = dataExport.map(row => {
        let res = {}
        columnsRuleChildAdditionalInfo.forEach(element => {
          res[element] = row[columnsRuleAdditionalInfoCode[element]]
        });
        return res
      })
      return dt
    }
    else {
      let dt = dataExport.map(row => {
        let res = {}
        columnsRule.forEach(element => {
          res[element] = row[columnsRuleCode[element]]
        });
        return res
      })
      return dt
    }
  }

  useEffect(() => {
    if (itemActive.Key === 5) {
      const sort = 'sequence:asc'
      onGetCodes(34, sort, '', true, () => {
        onGetResultTime();
      })
    }
    if (itemActive.Key === 4) {
      const sort = 'sequence:asc'
      onGetCodes(32, sort, '', true, () => {
        onGetAdditionalInfo();
      })
    }
    if (itemActive.Key === 3) {
      onGetMappingCodes({ instrumentId: itemActive.machineId, query: { inUse: true } }, () => {
        onGetRuleInstrumentRange(itemActive.machineId);
      })
    }
    if (itemActive.Key === 2) {
      onGetTests({ search: null, InUse: true, sort: 'DisplayOrder' }, () => {
        onGetRulePatientRange()
      })
    }
    if (itemActive.Key === 1) {
      onGetTests({ search: null, InUse: true, sort: 'DisplayOrder' }, () => {
        onGetRuleNormalRange()
      })
    }
    setRows([])
    itemActiveGlobal = itemActive
  }, [itemActive])

  const updateDataExport = (data) => {
    const vals = rows.length > 0 ? (data || []).filter(v => rows.findIndex(f => f.id == v.id) >= 0) : dataSource
    if (itemActive.Key == 2 && itemActive.parent.ID_Group == "1") {
      setDataExport(ConvertDataToParent(vals))
    } else if (itemActive.Key == 3 && itemActive.parent.ID_Group == "1") {
      setDataExport(ConvertDataToParentInstrument(vals))
    }
    else if (itemActive.Key == 4 && itemActive.parent.ID_Group == "1") {
      setDataExport(ConvertDataToChildAdditionalInfoExport(vals))
    }
    else if (itemActive.Key == 5 && itemActive.parent.ID_Group == "1") {
      setDataExport(ConvertDataToChildResultTimeExport(vals))
    }
    // else if (itemActive.Key == 6 && itemActive.parent.ID_Group == "1") {
    //   setDataExport(ConvertDataToParentAlert(vals))
    // }
    else {
      setDataExport(ConvertDataToNormalRange(vals))
    }
  }

  useEffect(() => {
    updateDataExport(dataSource)
  }, [rows])

  const checkDuplicateRule = (data) => {
    let ids = data.map(item => `${item.age}-${item.ageType}-${item.gender}-${item.testCode}`)
    ids = ids.filter((item, index) => item.age !== undefined || item.gender !== undefined)
    const duplicates = ids.filter((item, index) => ids.indexOf(item) !== index && (item.age !== undefined || item.gender !== undefined))
    if (duplicates.length > 0) {
      setDuplicateRule(duplicates)
      setWarningDuplicate(true)
      return true
    }
    return false
  }

  const checkEmptyRule = (data) => {
    const ruleEmpty = data.filter(item => (!(item.age && item.ageType) && !item.gender) && (item.age !== undefined || item.gender !== undefined))
    if (ruleEmpty.length > 0) {
      const name = ruleEmpty.map(item => `${item.testCode}`)
      setValueEmpty(name)
      setWarningValueEmpty(true)
      return true
    }
    return false
  }


  const submitUpdate = () => {
    let dt = getDataSunmit()
    if (dt.rules) {
      dt.rules = dt.rules.filter(x => x.enable == true)
    }
    if (itemActive.Key === 2 || itemActive.Key === 3) {
      if (checkEmptyRule(dt.rules)) {
        return
      }

      if (checkDuplicateRule(dt.rules)) {
        return
      }
    }


    let type = RuleManagementType.normal
    if (itemActive.Key === 2 && itemActive.parent.ID_Group == "1") {
      type = RuleManagementType.patient
    }
    else if (itemActive.Key === 3 && itemActive.parent.ID_Group == "1") {
      type = RuleManagementType.instrument
    }
    else if (itemActive.Key === 5 && itemActive.parent.ID_Group == "1") {
      type = RuleManagementType.resultTime
    }
    else if (itemActive.Key === 6 && itemActive.parent.ID_Group == "1") {
      type = RuleManagementType.instrumentAlert
    }
    else if (itemActive.Key === 4 && itemActive.parent.ID_Group == "1") {
      type = RuleManagementType.additionalInfo
    }
    if (dt.rules && dt.rules.length === 0) {
      setConfirmModal(false)
      setWarningModalEmpty(true)
      return;
    }

    try {
      if (itemActive.Key === 5) {
        onUpdateResultTime({
          ...dt
        }, () => {
          setConfirmModal(false)
          setFileName(t("common:Upload"))
          fetchTests()
          document.getElementById("importExcel").value = ""
        })
      } else if (itemActive.Key === 4) {
        onUpdateAdditionalInfo({
          ...dt
        }, () => {
          setConfirmModal(false)
          setFileName(t("common:Upload"))
          fetchTests()
          document.getElementById("importExcel").value = ""
        })
      } else if (isUpload) {
        onImportRuleManagement({
          rules: dt, type, machineId: itemActive.machineId, callback: () => {
            setConfirmModal(false)
            setFileName(t("common:Upload"))
            fetchTests()
            document.getElementById("importExcel").value = ""
          }
        })
      } else {
        onUpdateRuleManagement({
          rules: dt, type, machineId: itemActive.machineId, callback: () => {
            setConfirmModal(false)
            setFileName(t("common:Upload"))
            fetchTests()
            document.getElementById("importExcel").value = ""
          }
        })
      }
    } catch (error) {
      setConfirmModal(false)
    }
    setRows([])
  }

  const getDataSunmit = () => {
    let tmp = dataSource
    tmp = tmp.filter(x => rows.findIndex(a => a.id == x.id) >= 0)
    let res = []
    if (itemActive.Key == 2 && itemActive.parent.ID_Group == "1") {
      for (let index = 0; index < tmp.length; index++) {
        const element = tmp[index]
        if (element.children && element.children.length > 0) {
          for (let indexChild = 0; indexChild < element.children.length; indexChild++) {
            const x = element.children[indexChild]
            if (x.testCode && x.testCode != "") {
              let newVal = {
                "unit": x.unit || '',
                "normalRange": x.normalRange || '',
                "expression": x.expression || '1==1',
                "defaultResult": x.defaultResult || '',
                "lowerLimit": x.lowerLimit || '',
                "upperLimit": x.upperLimit || '',
                "lowerAlert": x.lowerAlert || '',
                "upperAlert": x.upperAlert || '',
                "enable": !!x.enable,
                "age": x.age || '',
                "gender": x.gender || '',
                "ageType": x.ageType || '',
              }
              if (!CheckEmptyAllValueObject(newVal)) {
                newVal.testCode = x.testCode
                if (!CheckExistObjectInArray(newVal, res)) {
                  res.push(newVal)
                }
              }
            }
          }
        } else {
          res.push({ testCode: element.testCode })
        }
      }
    } else if (itemActive.Key == 3 && itemActive.parent.ID_Group == "1") {
      for (let index = 0; index < tmp.length; index++) {
        const element = tmp[index];
        if (element.childrenInstrument && element.childrenInstrument.length > 0) {
          for (let indexChild = 0; indexChild < element.childrenInstrument.length; indexChild++) {
            const x = element.childrenInstrument[indexChild]
            if (x.testCode && x.testCode != "") {
              const newVal = {
                "unit": x.unit || '',
                "normalRange": x.normalRange || '',
                "expression": x.expression || '1==1',
                "defaultResult": x.defaultResult || '',
                "lowerLimit": x.lowerLimit || '',
                "upperLimit": x.upperLimit || '',
                "lowerAlert": x.lowerAlert || '',
                "upperAlert": x.upperAlert || '',
                "enable": !!x.enable,
                // "protocol": x.protocol || '',
                // "instrument": x.instrument || '',
                "age": x.age || '',
                "gender": x.gender || '',
                "ageType": x.ageType || '',
              }
              if (!CheckEmptyAllValueObject(newVal)) {
                newVal.testCode = x.testCode
                if (!CheckExistObjectInArray(newVal, res)) {
                  res.push(newVal)
                }
              }
            }
          }
        } else {
          res.push({ testCode: element.testCode })
        }
      }
    }
    else if (itemActive.Key == 4 && itemActive.parent.ID_Group == "1") {
      for (let index = 0; index < tmp.length; index++) {
        const element = tmp[index];
        if (element.childrenAdditionalInfo && element.childrenAdditionalInfo.length > 0) {
          for (let indexChild = 0; indexChild < element.childrenAdditionalInfo.length; indexChild++) {
            const x = element.childrenAdditionalInfo[indexChild];
            if (x.sampleType && x.sampleType != "") {
              const newVal = {
                "subCategory": x.subCategory || '',
                "testCode": x.testCode || '',
                "subId": x.subId || '',
                "color": x.color || '',
                "labels": x.labels || '',
                "expression": x.expression || '1==1',
                "enable": !!x.enable,
              }
              if (!CheckEmptyAllValueObject(newVal)) {
                newVal.sampleType = x.sampleType
                if (!CheckExistObjectInArray(newVal, res)) {
                  res.push(newVal)
                }
              }
            }
          }
        }
      }
    }
    else if (itemActive.Key == 5 && itemActive.parent.ID_Group == "1") {
      for (let index = 0; index < tmp.length; index++) {
        const element = tmp[index];
        if (element.childrenResultTime && element.childrenResultTime.length > 0) {
          for (let indexChild = 0; indexChild < element.childrenResultTime.length; indexChild++) {
            const x = element.childrenResultTime[indexChild];
            if (x.subCategory && x.subCategory != "") {
              const newVal = {
                "resultTime": x.resultTime || '',
                "timeCollection": x.timeCollection || '',
                "testCode": x.testCode || '',
                "profileCode": x.profileCode || '',
                "serviceType": x.serviceType || '',
                "inPatient": x.inPatient || '',
                "expression": x.expression || '1==1',
                "enable": !!x.enable,
              }
              if (!CheckEmptyAllValueObject(newVal)) {
                newVal.subCategory = x.subCategory
                if (!CheckExistObjectInArray(newVal, res)) {
                  res.push(newVal)
                }
              }
            }
          }
        }
      }
    }
    else {
      res = tmp.map(x => ({
        testCode: x.testCode,
        "unit": x.unit || '',
        "normalRange": x.normalRange || '',
        //"expression": x.expression || '',
        "defaultResult": x.defaultResult || '',
        "lowerLimit": x.lowerLimit || '',
        "upperLimit": x.upperLimit || '',
        "lowerAlert": x.lowerAlert || '',
        "upperAlert": x.upperAlert || '',
        "enable": !!x.enable,
        "expression": x.expression || '1==1',
      }))
    }
    return { rules: res }
  }

  const onChooseCustomWorkflow = (workflow) => {
    setCustomRule({ workflowId: workflow?.id, name: workflow?.workflowName })
  }

  const onToggle = () => {
    setOpen(!open)
    onGetRules(customRule)
  }

  const onToggleDeleteRule = () => {
    if (!rowRule || rowRule.length === 0) {
      setWarningModal(true)
      return;
    }
    setWarningDeleteRule(!warningDeleteRule);
  }

  const deleteCustomRule = () => {
    onDeleteCustomRule({ id: rowRule }, () => {
      onToggleDeleteRule()
      setRowRule([])
      onGetRules(customRule)
    })
  }

  const onSelectRow = (value, isSelected) => {
    if (isSelected) {
      setRowRule(prev => [...prev, value.id])
    } else {
      setRowRule(prev => [...prev.filter(x => x !== value.id)])
    }
  }

  const getTestName = (data) => {
    return data.map(item => {
      const value = item.split('-')
      return value[value.length - 1]
    })
  }

  const GetTypeTestRule = () => {
    if (itemActive.Key == 1 && itemActive.parent.ID_Group == "1") {
      return RuleTestType.NORMAL_RANGE
    } else if (itemActive.Key == 2 && itemActive.parent.ID_Group == "1") {
      return RuleTestType.PATIENT_RANGE
    } else if (itemActive.Key == 3 && itemActive.parent.ID_Group == "1") {
      return RuleTestType.INSTRUMENT_RANGE
    }
    else if (itemActive.Key == 4 && itemActive.parent.ID_Group == "1") {
      return RuleTestType.ADDITIONAL
    }
    else if (itemActive.Key == 5 && itemActive.parent.ID_Group == "1") {
      return RuleTestType.RESULT_TIME
    }
    else {
      return "";
    }
  }
  const isCustomWorkflow = !!customRule.workflowId || customRule.workflowId === 0

  return (
    <React.Fragment>

      <div className="row" style={{ height: "100%", paddingTop: 5 }}>
        <CustomSplitPane
          LeftFrame={() => (
            <TreeViewBar_RulerManager
              onChange={val => {
                setItemActive(val)
                setFileName(t("common:Upload"))
                document.getElementById("importExcel").value = ""
                setSearch('')
                document.getElementById("importExcel").value = ""
              }}
              machines={machines}
              onChooseCustomWorkflow={onChooseCustomWorkflow}
            />
          )}
          RightFrame={() => (
            <>
              <div className="rule-management">
                <TitleAndTable
                  table={() => (
                    <span></span>
                  )}
                  resource={RESOURCE}
                  icon
                  isDisableClone
                  isHideEdit
                  isDropDown
                  buttons={() => (
                    <>
                      <CustomButton style={{ width: '130px' }} color="primary" outline
                        onClick={() => { setOpenRuleTest(true) }}>
                        {t('common:Rule Test')}
                      </CustomButton>
                      {isCustomWorkflow &&
                        <>
                          <CustomButton style={{ width: '130px' }} color="success"
                            onClick={() => { setOpen(true) }}>
                            {t('Rule Editor')}
                          </CustomButton>
                          <CustomButton color="danger" style={{ width: '130px' }}
                            onClick={onToggleDeleteRule}>
                            {t('Delete Rule')}
                          </CustomButton>
                        </>}
                      {!isCustomWorkflow && <CustomButton color="success"
                        onClick={() => {
                          if (rows.length == 0) {
                            setWarningModal(true)
                            return;
                          }
                          setConfirmModal(true)
                        }}
                      >{t('common:Save Config')}
                      </CustomButton>}
                      <input
                        id="importExcel"
                        name="reportTemplateName"
                        type="file"
                        className=" d-none"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={e => {
                          setFileName(e.target.files[0]?.name || t("common:Upload"))
                          handleImport(e)
                        }}
                      />
                      {
                        !isCustomWorkflow && <>
                          <CustomButton
                            isEdit
                            color="primary"
                            outline
                            onClick={() => {
                              $(`#importExcel`).click()
                              setIsUpload(true)
                            }}
                          >
                            {fileName}
                          </CustomButton>
                          <ButtonExport
                            rows={dataSource}
                            data={getDataExport()}
                            itemActive={itemActive}
                            columns={getColumnExport()}
                          />
                        </>
                      }
                    </>
                  )}
                  title="Workflow"
                  subtitle={customRule?.name ? customRule.name : itemActive.Name}
                />
              </div >
              {!isCustomWorkflow && <React.Fragment>
                <RuleManagementTable
                  updatedTime={updatedTime}
                  itemActive={itemActive}
                  onSelect={onSelectCheckbox}
                  data={dataSource}
                  rows={rows}
                  row={row}
                  search={search}
                  onSelectAll={onSelectAllCheckbox}
                  onSearch={onSearch}
                  onRefresh={onRefreshHandler}
                  paging={paging}
                  ref={ref}
                  onChangeData={(datas) => {
                    updateDataExport(datas)
                  }}
                  onSort={(a, b) => {
                    onSortHandler(a, b);
                  }}
                />
              </React.Fragment >}
              {isCustomWorkflow && <CustomRule onSelect={onSelectRow} customRule={customRule} />}
            </>
          )}
          InitialSize={82}
        />
      </div>

      <CustomModal
        modal={open}
        onToggle={onToggle}
        fullscreen
      >
        <ModalBody>
          <iframe width='100%' height='100%' src={`${process.env.REACT_APP_RULE_MANAGEMENT}workflow?id=${customRule.workflowId}`}></iframe>
        </ModalBody>
      </CustomModal>

      <ConfirmModal
        title="Delete Rule"
        modal={warningDeleteRule}
        onDelete={() => deleteCustomRule()}
        message={`${t("message:DeleteRecordConfirm", {
          entity: `${t("Rule")}`,
        })}`}
        onToggle={onToggleDeleteRule}
      />
      <WarningModal
        modal={warningModal}
        onToggle={() => setWarningModal(prev => !prev)}
        message={t("message:SelectRowWarning")}
      />
      <WarningModal
        modal={warningModalEmpty}
        onToggle={() => setWarningModalEmpty(prev => !prev)}
        message={t("message:EmptyFormMessage")}
      />
      <WarningModal
        modal={warningValueEmpty}
        onToggle={() => setWarningValueEmpty(prev => !prev)}
        message={`${t('testConfig:Empty Rule')}: ${getTestName(valueEmpty).join(',')}`}
      />
      <WarningModal
        modal={warningDuplicate}
        onToggle={() => setWarningDuplicate(prev => !prev)}
        message={`${t('testConfig:Duplicate Rule')}: ${getTestName(duplicateRule).join(',')}`}
      />
      <ConfirmModal2
        modal={confirmModal}
        title={t("common:Config")}
        message={t("common:Save Config?")}
        onToggle={() => { setConfirmModal(prev => !prev) }}
        onConfirm={submitUpdate}
        btnConfirmText={t("common:Save")}
        isEdit
      />
      <RuleTestModal
        modal={openRuleTest}
        toggle={() => { setOpenRuleTest(prev => !prev) }}
        type={GetTypeTestRule()}
        itemActive={itemActive}
        workflowId={customRule.workflowId}
      />
    </React.Fragment >
  )
}

const mapStateToProps = ({ RuleManagement, machine }) => ({
  machines: machine.machines,
  tests: RuleManagement.tests,
  paging: RuleManagement.paging,
  updatedTime: RuleManagement.updatedTime
})

const mapDispatchToProps = dispatch => ({
  onGetMachines: payload => dispatch(getMachines(payload)),
  onGetMappingCodes: (payload, callback) => dispatch(getMachineCodeMapping(payload, callback)),
  onGetTests: (payload, callback) => dispatch(getFullTestRule(payload, callback)),
  onGetRuleNormalRange: (payload, callback) => dispatch(getRuleNormalRange(payload, callback)),
  onGetRulePatientRange: (payload, callback) => dispatch(getRulePatientRange(payload, callback)),
  onGetRuleInstrumentRange: (payload, callback) => dispatch(getRuleInstrumentRange(payload, callback)),
  onGetRuleInstrumentAlert: (payload, callback) => dispatch(getRuleInstrumentAlert(payload, callback)),
  onGetRuleRulerBase: (payload, callback) => dispatch(getRuleRulerBase(payload, callback)),
  onUpdateRuleManagement: (payload) => dispatch(updateRuleManagement(payload)),
  onGetTestsSuccess: payload => dispatch(getFullTestRuleSuccess(payload)),
  onResetTestSearchQuery: () => dispatch(resetSearchQuery()),
  getCustomWorkflowList: payload => dispatch(getWorkflowCustom(payload)),
  onGetRules: payload => dispatch(getRuleCustom(payload)),
  onDeleteCustomRule: (payload, callback) => dispatch(deleteRuleCustom(payload, callback)),
  onGetCodes: (paramId, sort, search, inUse, callback) => dispatch(getCodesWithPage({ paramId, sort, search, inUse, callback })),
  onGetRuleResultTime: (payload, callback) => dispatch(getRuleResultTime(payload, callback)),
  onGetResultTime: (payload, callback) => dispatch(getResultTime(payload, callback)),
  onUpdateResultTime: (payload, callback) => dispatch(updateResultTime(payload, callback)),
  onGetAdditionalInfo: (payload, callback) => dispatch(getAdditionalInfo(payload, callback)),
  onUpdateAdditionalInfo: (payload, callback) => dispatch(updateAdditionalInfo(payload, callback)),
  onImportRuleManagement: (payload, callback) => dispatch(importRuleManagement(payload, callback)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["message, common, testConfig"])(RuleManagements)))