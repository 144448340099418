import {
    Check
} from "components/Common";
import { permissionType, RESULT_STATE_Text } from "constant";
import { getGeneralSetting } from "helpers/app-backend/general_setting_backend_helper";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";

const HeaderButtons = ({
    resource,
    t,
    onClick,
    disabled,
    onValid,
    onInValid,
    onRefresh,
    rows,
    onSubmitConfirm,
    onSubmitCancelConfirm,
}) => {

    const isContainValid = useMemo(() => rows.some(item => item.state >= RESULT_STATE_Text.Validated && item.state !== RESULT_STATE_Text.Canceled && item.state !== RESULT_STATE_Text.RDS), [rows]);
    const isContainInValid = useMemo(() => rows.some(item => item.state < RESULT_STATE_Text.Validated && item.state !== RESULT_STATE_Text.Canceled && item.state !== RESULT_STATE_Text.RDS), [rows]);
    const isConfirm = useMemo(() => rows.some(item => item.state >= RESULT_STATE_Text.Confirmed && item.state !== RESULT_STATE_Text.Canceled && item.state !== RESULT_STATE_Text.RDS), [rows]);
    const isUnConfirm = useMemo(() => rows.some(item => item.state < RESULT_STATE_Text.Confirmed && item.state !== RESULT_STATE_Text.Canceled && item.state !== RESULT_STATE_Text.RDS), [rows]);
    const [generalSetting, setGeneralSetting] = useState([])
    const [approvalLevel, setApprovalLevel] = useState(false)

    const GetGeneralSettingList = async () => {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"))
        const res = await getGeneralSetting(userInfo.company)
        if (res && res.length > 0) {
          setGeneralSetting(res)
        }
      }

    useEffect(() => {
        if (generalSetting && generalSetting.length > 0) {
            setApprovalLevel(generalSetting.find(x => x.fieldName == "ApprovalLevel")?.fieldValue)
        }
    }, [generalSetting])

    useEffect(() => {
        GetGeneralSettingList()
    }, [])
    const renderButton = (onClickHandler, iconClass, text, isDisabled) => (
        <button disabled={disabled || isDisabled} className="btn btn-light" onClick={onClickHandler}>
            <span style={{ marginRight: 10 }}>
                <i className={`fas ${iconClass}`}></i>
            </span>
            {t(text)}
        </button>
    );

    return (
        <>
            <Check permission={permissionType.R} resource={resource}>
                {renderButton(onRefresh, 'fa-sync-alt', 'Refresh', false)}
            </Check>

            {(approvalLevel == 'True' || approvalLevel == true) &&
            <Check permission={permissionType.Confirm} resource={resource}>
                {renderButton(onSubmitConfirm, 'fa-check', 'Confirm Result', !!isConfirm)}
            </Check>
            }

            {(approvalLevel == 'True' || approvalLevel == true) && 
            <Check permission={permissionType.Confirm} resource={resource}>
                {renderButton(onSubmitCancelConfirm, 'fa-info-circle', 'UnConfirm Result', !!isUnConfirm)}
            </Check>
            }

            <Check permission={permissionType.U} resource={resource}>
                {renderButton(onValid, 'fa-check', 'Valid Result', !!isContainValid)}
                {renderButton(onInValid, 'fa-info-circle', 'InValid Result', !!isContainInValid)}
                {renderButton(onClick, 'fa-pen', 'Modify Result', !!isContainValid)}
            </Check>
        </>
    );
};

HeaderButtons.propTypes = {
    resource: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    onValid: PropTypes.func,
    onInValid: PropTypes.func,
    onRefresh: PropTypes.func,
    loadingRefresh: PropTypes.bool,
    rows: PropTypes.arrayOf(PropTypes.shape({
        state: PropTypes.number.isRequired
    })).isRequired,
    onSubmitConfirm: PropTypes.func,
    onSubmitCancelConfirm: PropTypes.func,
};

export default withTranslation(["workListPage"])(HeaderButtons);