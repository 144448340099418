import { AvForm } from "availity-reactstrap-validation"
import classnames from "classnames"
import {
  CustomAvInput,
  CustomDatePicker,
  CustomSelect,
  showErrToast,
  showToast
} from "components/Common"
import { ModuleIds, PATIENT_VISIST_TAB, ReportResource, parameterCode } from "constant"
import { LisGetRequestOrder, getAuthorizePage, getCodesByParameterId, getFilePrint, getPatientVisitWaitingList, getTestrequestGeneralDetailById, getTestrequestSamplesDetailById, pingToPrintService, sendDataPrint } from "helpers/app-backend"
import { GetDataUrlReportConfig, convertDateFormat, getI18nextLng, trimAndRemoveMultipleSpaces } from "helpers/utilities"
import moment from "moment"
import { useEffect, useState, useRef } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import {
  Card,
  CardBody, Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap"
import { addNewHisConnector, deleteHisConnectors, getHisConnectorDetail, getHisConnectors, setItemActiveConnector, updateHisConnector, updateWaitingRequest } from "store/actions"
import CustomSearchBar from "./SearchBar"
import ToCollectPatient from "./ToCollectPatient"
import VissitedPatient from "./WaitingRequest"
import WatingPatient from "./WaitingPatient"
import ChangeSIDModal from "pages/Laboratory/PatientVisitNew/Modal/ChangeSIDModal"
import { useMemo } from "react"
import { isEmpty } from "lodash"
import WaitingRequest from "./WaitingRequest"

let task = null
let barCodeSearch = '';
let enableBarcodeGlobal = false;
const PatientListFrame = ({
  t,
  onSelectPatient,
  wattingPatient,
  toCollectPatient,
  onRefresh,
  onSubmit,
  onSubmitFilter,
  pagingWaiting,
  pagingCollected,
  patientDetail,
  onSelectBarcode,
  toggle,
  connectors,
  onGetHisConnectors,
  onChangeTab,
  requestDate,
  onUpdateWaitingRequest,
  generalSettingObject
}) => {

  let lang = getI18nextLng()
  const [modalSID, setModalSID] = useState(false)
  const [activeTab, setactiveTab] = useState("2")
  const [searchText, setSearchText] = useState('')
  const [collector, setCollector] = useState({})
  const [model, setModel] = useState({
    searchText: searchText,
    start: convertDateFormat(new Date(), "YYYY-MM-DD"),
    end: convertDateFormat(new Date(), "YYYY-MM-DD")
  });
  const [serviceTypes, setServiceTypes] = useState([])
  const [serviceType, setServiceType] = useState('')
  const [patientVisitConfig, setPatientVisitConfig] = useState({})
  const [enableBarcode, setEnableBarcode] = useState(false)
  const [keySearch, setKeySearch] = useState('')

  const [hisConnector, setHisConnector] = useState([])
  const [reportResultInfos, setReportResultInfos] = useState({})

  const formEl = useRef(null)
  useMemo(() => {
    onGetHisConnectors()
  }, [])

  const getReportResultInfos = async () => {
    const res = await getAuthorizePage(1, "_TestRequest")
    if (res) {
      setReportResultInfos(res._ReportInfo)
    }
  }

  useEffect(() => {
    getReportResultInfos()
  }, [])

  useEffect(() => {
    setHisConnector(connectors)
  }, [connectors])

  useEffect(() => {
    if (requestDate) {
      patientVisitConfig.requestDate = requestDate
      setPatientVisitConfig({ ...patientVisitConfig })
    }
  }, [requestDate])

  useEffect(() => {
    if (activeTab) {
      onSubmitFilter(null, model, activeTab)
    }
  }, [activeTab])

  const hisConnectorOptions = hisConnector.filter(connector => connector.status === true).map(_connector => {
    return { value: _connector.code, label: _connector.name }
  })

  useEffect(() => {
    if (keySearch.length > 0 && enableBarcode) {
      if (patientVisitConfig && patientVisitConfig.autoSID == true) {
        GetRequest(keySearch)
        setTimeout(() => {
          setKeySearch('')
        }, 1000);
      } else {
        setModalSID(true);
      }
    }
  }, [keySearch])

  useEffect(() => {
    setKeySearch('')
    enableBarcodeGlobal = enableBarcode
  }, [enableBarcode])

  const GetRequest = async (keySearch, sid) => {
    try {
      onSelectBarcode && onSelectBarcode(true)
      const req = await LisGetRequestOrder(keySearch, patientVisitConfig?.requestDate ? patientVisitConfig?.requestDate : moment().format("YYYY-MM-DD")
        , hisConnectorOptions.length < 2 ? hisConnectorOptions[0]?.value : patientVisitConfig?.connector ? patientVisitConfig?.connector : "", sid
        , patientVisitConfig.autoCollected || false, collector.collectorId || '')
      let samplesInfo = {}
      let generalInfo = {}

      if (req && req.id != 0) {
        //Tiếp nhận thành công mã hồ sơ
        showToast(
          `${t("message:ReceiveFileSuccess", {
            field: `${keySearch}`,
          })}`
        )
        toggle()
        onRefreshHandler(activeTab)
        onChangeTab && onChangeTab('2')
        setKeySearch('')
        let item = {}

        if (activeTab === '1') {
          const res = await getPatientVisitWaitingRequestList({ size: 1, id: req.id })
          if (res !== null && res.data && res.data.length > 0) {
            item = res.data[0]
          }
        }

        if (activeTab === '2') {
          const res = await getPatientVisitWaitingList({ size: 1, id: req.id })
          if (res !== null && res.data && res.data.length > 0) {
            item = res.data[0]
          }
        }
        else if (activeTab === '3') {
          const res = await getPatientVisitWaitingList({ size: 1, id: req.id })
          if (res !== null && res.data && res.data.length > 0) {
            item = res.data[0]
          }
        }
        if (item.id) {
          onSelectPatient("", item)
          const res = await getTestrequestSamplesDetailById(item.id)
          const general = await getTestrequestGeneralDetailById(item.id)

          if (res !== null && res?.samples) {
            samplesInfo = res.samples
            generalInfo = general
          }
        }
      }
      else {
        showErrToast(
          `${t("message:AlternateIdNotFound", {
            field: `${keySearch}`,
          })}`
        )
      }
      if (req && req.id != 0 && patientVisitConfig.autoPrintBarcode == true) {
        PrintConfig(samplesInfo, generalInfo)
      }
      onSelectBarcode && onSelectBarcode(false)
      setModalSID(false)
    } catch (error) {
      onSelectBarcode && onSelectBarcode(false)
      setModalSID(false)
    }
  }

  const PrintConfig = async (samplesInfo, generalInfo) => {

    const print = JSON.parse(localStorage.getItem("printConfig"))
    let printColumns = Number(print.columns)
    let printPage = ['B1', 'B2', 'B3']

    let sampleListType = samplesInfo?.map((obj, index) => ({ ...obj, id: `${index + 1}-${obj?.sid}` }))
    let SID = sampleListType?.map((_slt) => _slt?.sid?.toString())
    let subSID = sampleListType?.map((_slt) => _slt?.subSID)
    let sampleType = sampleListType?.map((_slt) => _slt?.sampleType?.toString())

    const formattedSampleType = sampleType?.map(item => `'${item}'`)

    if (reportResultInfos.length > 0 && !isEmpty(reportResultInfos[0].uri)) {
      const res = await getFilePrint({
        ...GetDataUrlReportConfig(reportResultInfos, ReportResource.RequestId, ""),
        SID: SID?.length > 1 ? `[${SID}]` : SID[0],
        SubSID: subSID?.length > 1 ? `[${subSID}]` : subSID[0],
        RequestDate: generalInfo.requestDate,
        SampleType: formattedSampleType?.length > 1 ? `[${formattedSampleType}]` : formattedSampleType[0].replace(/['"]/g, ""),
        Page: printPage[printColumns - 1]
      })
      try {
        const getPingPrintService = await pingToPrintService();
        if (getPingPrintService.pingTime) {
          const dataSendPrint = await sendDataPrint({
            ...GetDataUrlReportConfig(reportResultInfos, ReportResource.RequestId, ""),
            "filePath": `${res.reportUrl}`,
          })
        } else {
          window.open(process.env.REACT_APP_BASE_ENDPOINT + res?.reportUrl)
        }
      }
      catch {
        window.open(process.env.REACT_APP_BASE_ENDPOINT + res?.reportUrl)
      }
    }
    else {
      console.log('failed')
    }
  }

  useEffect(() => {
    function handlekeydownEvent(event) {
      const { key, keyCode } = event;
      if (enableBarcodeGlobal == true) {
        if (keyCode == 13) {
          setKeySearch(barCodeSearch)
          barCodeSearch = ``;
          if (task)
            clearTimeout(task)
        }
        else {
          if (key != '' && key != null)
            barCodeSearch += `${key}`;
          task = setTimeout(() => {
            barCodeSearch = "";
          }, 500);
        }
      }
    }
    document.addEventListener('keydown', handlekeydownEvent)
    return () => {
      document.removeEventListener('keydown', handlekeydownEvent)
    }
  }, [])

  const item = localStorage.getItem("patientVisitConfig")
  const defaultCollector = localStorage.getItem("defaultCollector")

  const getPatientVisitConfig = () => {
    if (item) {
      try {
        setPatientVisitConfig(JSON.parse(item))
      } catch (error) {

      }
    }
    if (defaultCollector) {
      try {
        setCollector(JSON.parse(defaultCollector))
      } catch (error) {

      }
    }
  }
  useEffect(() => {
    getPatientVisitConfig()
    window.addEventListener('storage', getPatientVisitConfig)
    getServiceType()
    return () => {
      window.removeEventListener('storage', getPatientVisitConfig)
    }
  }, [item])

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }

  const getServiceType = async () => {
    const query = { lang }
    let res = await getCodesByParameterId(parameterCode.SERVICE_TYPE, query);
    res?.map(_item => {
      _item.value = _item.code
      _item.label = _item.message
      return _item
    });
    res = res || []
    setServiceTypes(res)
  }

  const onRefreshHandler = (activeTab) => {
    onRefresh(activeTab)
    setModel({
      ...model,
      searchText: "",
      physicianId: "",
      departmentId: "",
      groupCode: ""
    })
  }

  const onSubmitFilterPatient = (e, values) => {
    onSubmitFilter(e, values, activeTab)
  }

  const onSidUpdate = () => {
    onUpdateWaitingRequest()
  }

  const minDate = new Date().setMonth(new Date().getMonth() - 3)
  const patientDate = useMemo(() => [model.start, model.end], [model.start, model.end])

  return (
    <>
      <Card
        className="patient-visit mb-0 pb-0"
        style={{ maxWidth: "100%", maxHeight: "80%" }}
      >
        <CardBody className="p-2 px-0 position-relative">
          <div className="d-flex flex-wrap flex-row">
            <div style={{ width: "100%" }}>
              <AvForm>
                <div className="d-flex gap-1">
                  <CustomAvInput
                    name={`barCode`}
                    placeholder={t("patientVisitPage:Barcode Search")}
                    value={keySearch}
                    disabled={enableBarcode}
                    onChange={e => {
                      setKeySearch(e.target.value)
                    }}
                    onKeyDown={e => {
                      if (e.keyCode == 13) {
                        if (
                          patientVisitConfig &&
                          patientVisitConfig.autoSID == true
                        ) {
                          GetRequest(keySearch)
                        } else {
                          if (keySearch.length > 0) {
                            setModalSID(true)
                          }
                        }
                      }
                    }}
                  />
                  <div>
                    <div
                      type="button"
                      className={`px-3 btn ${!enableBarcode ? "btn-outline-primary" : "btn-primary"
                        }`}
                      onClick={() => {
                        setEnableBarcode(prev => !prev)
                      }}
                      style={{ width: "60px" }}
                    >
                      <i
                        style={{ fontSize: "16px" }}
                        className="fa fa-barcode"
                      ></i>
                    </div>
                    {enableBarcode && (
                      <div>
                        <strong></strong>
                      </div>
                    )}
                  </div>
                </div>
              </AvForm>
            </div>
          </div>
          <div className="mt-2">
            <Nav
              pills
              className="navtab-bg nav-justified mb-1"
              style={{ border: "1px solid #ccc", borderRadius: 6 }}
            >
              {[PATIENT_VISIST_TAB.Basic, PATIENT_VISIST_TAB.fpt].includes(generalSettingObject?.PatientVisitTab) && <NavItem>
                <NavLink
                  style={{
                    cursor: "pointer",
                    color: activeTab === "1" ? "#fff" : "#556ee6",
                  }}
                  className={classnames({
                    active: activeTab === "1",
                  })}
                  onClick={() => {
                    toggleTab("1")
                  }}
                >
                  {t("patientVisitPage:Waiting Request")}
                </NavLink>
              </NavItem>}
              <NavItem>
                <NavLink
                  style={{
                    cursor: "pointer",
                    color: activeTab === "2" ? "#fff" : "#556ee6",
                  }}
                  className={classnames({
                    active: activeTab === "2",
                  })}
                  onClick={() => {
                    toggleTab("2")
                  }}
                >
                  {t("Waiting")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{
                    cursor: "pointer",
                    color: activeTab === "3" ? "#fff" : "#556ee6",
                  }}
                  className={classnames({
                    active: activeTab === "3",
                  })}
                  onClick={() => {
                    toggleTab("3")
                  }}
                >
                  {t("Collected")}
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab} className="text-muted">
              <div className="d-flex flex-wrap flex-row mb-1">
                <div className="" style={{ width: "100%" }}>
                  <CustomSearchBar
                    placeholder={t("Search SID, PID, Patient Name")}
                    searchText={searchText}
                    width={"100%"}
                    style={{ height: "36px" }}
                    onSearch={e => {
                      setSearchText(e.target.value)
                    }}
                    onClear={() => {
                      setSearchText("")
                      onSubmit("", activeTab)
                    }}
                    onSubmit={() => {
                      onSubmit(
                        trimAndRemoveMultipleSpaces(searchText),
                        activeTab
                      )
                    }}
                  />
                </div>
              </div>
              <AvForm>
                <div className="d-flex gap-1">
                  <div className="mb-1" style={{ width: "50%" }}>
                    <CustomDatePicker
                      name="requestDate"
                      label={""}
                      format={"d-m-Y"}
                      value={patientDate}
                      closeOnSelect={true}
                      onChangeHandler={date => {
                        if (date.time.length == 2) {
                          setModel({
                            ...model,
                            start: convertDateFormat(
                              date.time[0],
                              "YYYY-MM-DD"
                            ),
                            end: convertDateFormat(date.time[1], "YYYY-MM-DD"),
                          })
                          onSubmitFilter(
                            "",
                            {
                              ...model,
                              start: convertDateFormat(
                                date.time[0],
                                "YYYY-MM-DD"
                              ),
                              end: convertDateFormat(
                                date.time[1],
                                "YYYY-MM-DD"
                              ),
                            },
                            activeTab
                          )
                        }
                      }}
                      maxDate={new Date()}
                      minDate={minDate}
                      mode="range"
                    />
                  </div>
                  <div className="mb-1" style={{ width: "50%" }}>
                    <CustomSelect
                      name="serviceType"
                      options={serviceTypes || []}
                      label={""}
                      onChange={(e, vals) => {
                        setModel({ ...model, serviceType: vals[0] })
                        onSubmitFilter(
                          "",
                          { ...model, serviceType: vals[0] },
                          activeTab
                        )
                      }}
                      placeholder={t("common:ServiceType")}
                      value={serviceType}
                    />
                  </div>
                </div>
              </AvForm>
              {[PATIENT_VISIST_TAB.Basic, PATIENT_VISIST_TAB.fpt].includes(generalSettingObject?.PatientVisitTab) && <TabPane tabId="1">
                <Row>
                  <Col sm="12" >
                    <WaitingRequest
                      t={t}
                      // onSelectPatient={onSelectPatient}
                      data={wattingPatient}
                      pagingWaiting={pagingWaiting}
                      patientDetail={patientDetail}
                      model={model}
                      onSubmitFilterPatient={onSubmitFilterPatient}
                      onResetHandler={onRefreshHandler}
                      onSidUpdate={onSidUpdate}
                    />
                  </Col>
                </Row>
              </TabPane>}
              <TabPane tabId="2">
                <Row>
                  <Col sm="12">
                    <WatingPatient
                      t={t}
                      onSelectPatient={onSelectPatient}
                      data={wattingPatient}
                      pagingWaiting={pagingWaiting}
                      patientDetail={patientDetail}
                      model={model}
                      onSubmitFilterPatient={onSubmitFilterPatient}
                      onResetHandler={onRefreshHandler}
                    />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="3">
                <Row>
                  <Col sm="12">
                    <ToCollectPatient
                      data={toCollectPatient}
                      t={t}
                      onSelectPatient={onSelectPatient}
                      activeTab={3}
                      pagingCollected={pagingCollected}
                      patientDetail={patientDetail}
                      onSubmitFilterPatient={onSubmitFilterPatient}
                      onResetHandler={onRefreshHandler}
                      model={model}
                    />
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </div>
        </CardBody>
      </Card>
      <ChangeSIDModal
        formEl={formEl}
        modal={modalSID}
        isEdit={false}
        onValidSubmit={sid => {
          if (keySearch.length > 0 && enableBarcode) {
            GetRequest(keySearch, sid)
            setTimeout(() => {
              setKeySearch("")
            }, 1000)
          } else {
            GetRequest(keySearch, sid)
          }
        }}
        toggle={() => {
          setModalSID(!modalSID)
        }}
      />
    </>
  )
}

const mapStateToProps = ({ hisConnector, testRequest, generalSetting }) => ({
  connectors: hisConnector.hisConnectors || [],
  hisConnector: hisConnector.hisConnector || {},
  samples: testRequest.testRequest.samples,
  generalSettingObject: generalSetting.generalSettingObject
})

const mapDispatchToProps = dispatch => ({
  onSetItemActiveConnector: payload => dispatch(setItemActiveConnector(payload)),
  onGetHisConnectors: payload => dispatch(getHisConnectors(payload)),
  onAddNewHisConnector: (hisConnector) => dispatch(addNewHisConnector(hisConnector)),
  onUpdateHisConnector: hisConnector => dispatch(updateHisConnector(hisConnector)),
  onDeleteHisConnector: hisConnector => dispatch(deleteHisConnectors(hisConnector)),
  onGetHisConnectorDetail: (id, callback) => dispatch(getHisConnectorDetail(id, callback)),
  onUpdateWaitingRequest: () => dispatch(updateWaitingRequest())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["testRequestPage", "message", "patientVisitPage"])(PatientListFrame))
