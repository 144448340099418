import {
    CustomButton, CustomModal
} from "components/Common";
import CustomBootstrapInlineTable from "components/Common/CustomInlineTable/CustomBootstrapInlineTable";
import { AuditLogIdentifier } from "constant";
import { getTestById } from "helpers/app-backend";
import { convertDateFormat } from "helpers/utilities";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ModalBody, ModalFooter } from "reactstrap";
import { testRequestAudit } from "store/actions";

const RequestHistoryModal = ({
    modal,
    toggle,
    data,
    formEl,
    t,
    testRequestHistory,
    onGetTestRequestAudit,
    ...rest
}) => {
    const title = t("testRequestPage:History Delete")
    const [dataSource, setDataSource] = useState([])

    useEffect(() => {
        if (modal) {
            onGetTestRequestAudit([
                AuditLogIdentifier.LA_TestRequest + data.id,
            ])
            setDataSource([])
        }
    }, [modal])

    useEffect(() => {
        if (testRequestHistory?.length > 0) {
            const historyDelete = testRequestHistory.filter(item => item.payload.includes('TestDelete')).map(item => ({ ...item, payload: JSON.parse(item.payload) }))
            if (historyDelete.length > 0) {
                let historyDeleteData = []
                historyDelete.forEach(x => {
                    x.payload.TestDelete?.split(',')?.forEach(async item => {
                        historyDeleteData.push({
                            code: item,
                            userName: x.userName,
                            updatedDate: convertDateFormat(x.timeStamp)
                        })
                    })
                })
                getTestName(historyDeleteData)

            }
        }
    }, [testRequestHistory])

    const getTestName = async (items) => {
        for (const item of items) {
            const res = await getTestById(item.code)
            item.testName = res.testName
        }
        setDataSource([...items])
    }

    const columns = [
        {
            dataField: "id",
            text: "id",
            hidden: true,
            searchable: false,
        },
        {
            dataField: "idx",
            text: "#",
            searchable: false,
            headerStyle: { width: "50px" },
            formatter: (cellContent, code, index) => {
                return index + 1
            },
        },
        {
            dataField: "code",
            text: t("Code"),
        },
        {
            dataField: "testName",
            text: t("testPage:Test Name"),
        },
        {
            dataField: "username",
            text: t("User"),
            formatter: (cellContent, code, index) => {
                return <span>{code?.userName}</span>
            },
        },
        {
            dataField: "updatedDate",
            text: t("Updated Date"),
            formatter: (cellContent, code, index) => {
                return <span>{code?.updatedDate}</span>
            },
        }
    ]

    return (
        <CustomModal modal={modal} title={title} onToggle={toggle} size="lg" customClass={"model-height"} >
            <ModalBody>
                <>
                    <CustomBootstrapInlineTable
                        columns={columns}
                        data={dataSource || []}
                        isScrollable
                        isEnableLoadInfo={true}
                        searchText={""}
                        draggable
                        keyField={"id"}
                        {...rest}
                    />
                </>
            </ModalBody>
            <ModalFooter>
                <CustomButton
                    text={t("testResultPage:Cancel")}
                    type="button"
                    onClick={toggle}
                    data-dismiss="modal"
                    className="button-width"
                />
            </ModalFooter>
        </CustomModal>
    )
}

const mapStateToProps = ({ testResult }) => ({
    testRequestHistory: testResult.testResult.resultAuditLog || [],
})

const mapDispatchToProps = dispatch => ({
    onGetTestRequestAudit: payload => dispatch(testRequestAudit(payload)),
})

RequestHistoryModal.propTypes = {
}

export default withTranslation(["common", "testRequestPage","testPage"])(connect(mapStateToProps, mapDispatchToProps)(RequestHistoryModal))