import {
  CustomBootstrapTable, CustomDatePicker,
  CustomSID
} from "components/Common"
import {
  DeliveryStateText,
  ModuleIds,
  SAMPLES_DELIVERY_TYPE
} from "constant"
import { convertDateFormat } from "helpers/utilities"
import React, { useState } from "react"
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import ReactTooltip from "react-tooltip"
import QualitySample from "./QualitySample"
import RemarkSample from "./RemarkSample"
import ReasonReturn from "./ReasonReturn"
import { AvForm } from "availity-reactstrap-validation"
import moment from "moment"
import MethodSample from "./MethodSample"
const RESOURCE = ModuleIds.DeliveryReceive

const SampleTable = ({
  onSelect,
  onSelectAll,
  data,
  onEdit,
  onDelete,
  t,
  model,
  loading,
  onChangeRemark,
  onChangeQuality,
  deliveryType,
  onSubmit,
  delivery,
  isCollapse,
  onChangeReason,
  onChangeMethod,
  ...rest
}) => {
  const space = isCollapse ? 0 : 80

  let confirmedUserName = ''
  let confirmedTimeTitle = ''

  switch (deliveryType) {
    case SAMPLES_DELIVERY_TYPE.DeliveryAndReceipt:
      confirmedUserName = 'ReceiveName'
      confirmedTimeTitle = 'ReceiveTime'
      break
    case SAMPLES_DELIVERY_TYPE.Delivery:
      confirmedUserName = 'DeliveryName'
      confirmedTimeTitle = 'DeliveryTime2'
      break
    case SAMPLES_DELIVERY_TYPE.Receipt:
      confirmedUserName = 'ReceiveName'
      confirmedTimeTitle = 'Receive Time'
      break
    case SAMPLES_DELIVERY_TYPE.Correction:
      confirmedUserName = 'ReturnName'
      confirmedTimeTitle = 'ReturnTime'
      break
    case SAMPLES_DELIVERY_TYPE.Cancelation:
      confirmedUserName = 'CancelName'
      confirmedTimeTitle = 'CancelTime'
      break
  }

  const columns = [
    {
      dataField: "id",
      text: "#",
      style: { width: 50 },
      formatter: (cellContent, sample, index) => index + 1
    },
    {
      dataField: "deliveryId",
      text: "deliveryId",
      hidden: true,
      searchable: false,
    },
    {
      dataField: "sid",
      text: t("SID"),
      formatter: (cellContent, sample, index) => {
        return <CustomSID
          sid={`${sample.sid}${sample?.subSID ? `- ${sample.subSID}` : ''}`}
          textOnly={true}
        />
      }
    },
    {
      dataField: "patientName",
      text: t("Patient"),
      formatter: (cellContent, sample, index) => {
        return (
          <Link to={`/Individual/${sample.patientId}/view`} target="_blank">{cellContent}</Link>
        )
      },
    },
    {
      dataField: "sampleType",
      text: t("common:Code"),
      searchable: false,
    },
    {
      dataField: "sampleTypeName",
      text: t("Sample Name"),
    },
    ...(SAMPLES_DELIVERY_TYPE.Cancelation !== deliveryType ? [{
      dataField: "qualityName",
      text: t("Quality"),
      searchable: false,
      formatter: (cellContent, sample, index) => (
        <QualitySample
          sample={sample}
          onChangeQuality={onChangeQuality}
        />
      ),
    }] : []),
    ...((SAMPLES_DELIVERY_TYPE.Delivery === deliveryType || SAMPLES_DELIVERY_TYPE.Receipt === deliveryType) ? [{
      dataField: "methodName",
      text: t("Method"),
      searchable: false,
      formatter: (cellContent, method, index) => (
        <MethodSample
          data={method}
          onChangeMethod={onChangeMethod}
        />
      ),
    }] : []),
    ...(SAMPLES_DELIVERY_TYPE.Correction === deliveryType &&
      SAMPLES_DELIVERY_TYPE.Cancelation !== deliveryType
      ? [
        {
          dataField: "reasonName",
          text: t("Reason"),
          searchable: false,
          formatter: (cellContent, sample, index) => {
            return <ReasonReturn
              onChangeReason={onChangeReason}
              sample={sample}
            />
          }
        },
      ]
      : (SAMPLES_DELIVERY_TYPE.Cancelation !== deliveryType ? [
        {
          dataField: "remark",
          text: t("common:Remark"),
          searchable: false,
          style: { width: '120px' },
          formatter: (cellContent, sample) =>
            <RemarkSample
              sample={sample}
              onChangeRemark={onChangeRemark}
            />
        },
      ] : [])),
    {
      dataField: "confirmedUserName",
      text: t(confirmedUserName),
      searchable: false,
      formatter: (cellContent, sample, index) => {
        return (
          <Link to={`/User/${sample.confirmedUserId}/view`} target="_blank">
            {cellContent}
          </Link>
        )
      }
    },
    {
      dataField: "confirmedTime",
      text: t(confirmedTimeTitle),
      searchable: false,
      formatter: (cellContent, sample, index) => {
        // if (SAMPLES_DELIVERY_TYPE.DeliveryAndReceipt !== deliveryType) {
        return moment(cellContent).format("YYYY-MM-DD HH:mm:ss")
        // } else {
        //   return <AvForm>
        //     <div className='date-time-delivery'>
        //       < CustomDatePicker
        //         label={''}
        //         errorMessage=''
        //         name="confirmedTime"
        //         value={cellContent || new Date()
        //         }
        //         enableTime={true}
        //         dateFormat='Y-m-d H:i'
        //         onChangeHandler={(e) => {
        //           sample.confirmedTime = e.time[0]
        //         }}
        //       />
        //     </div>
        //   </AvForm>
        // }
      },
    },
    {
      dataField: "action",
      text: "",
      isDummyField: true,
      headerStyle: { width: "50px" },
      style: { width: 50 },
      formatter: (cellContent, sample, index) => (
        <div className="d-flex gap-3">
          <Link
            id={index}
            to="#"
            className="text-danger"
            onClick={e => {
              onDelete(sample)
            }}
          >
            <i
              className="mdi mdi-delete font-size-18"
              data-tip
              data-for={`deletetooltip-${index}`}
            />
            <ReactTooltip place="top" id={`deletetooltip-${index}`} effect="solid">
              {t('common:Delete')}
            </ReactTooltip>
          </Link>
        </div>
      ),
    },
  ]

  return (
    <React.Fragment>
      <div className="table-patient-visit">
        <CustomBootstrapTable
          columns={columns}
          data={data}
          onSelect={onSelect}
          onSelectAll={onSelectAll}
          isSelected
          loading={loading}
          searchText={model.search}
          resource={RESOURCE}
          paging={{
            dataSize: data.length,
          }}
          search={false}
          isEnableLoadInfo={true}
          isEnableRefresh={false}
          isEnableExport={false}
          updatedTime={new Date()}
          style={{ height: window.innerHeight >= 945 ? `${(window.innerHeight - 440) + space}px` : `${(window.innerHeight - 240) + space}px` }}
          {...rest}
        />
      </div>
    </React.Fragment>
  )
}

export default withTranslation(["deliveryPage", "common"])(SampleTable)
