import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del } from "../api_helper2"

const BASE_API_URL = `${process.env.REACT_APP_LABORATORY_ENDPOINT}/results`
const BASE_MS_API_URL = `${process.env.REACT_APP_MASTER_SETUP_ENDPOINT}/global`
const BASE_API_URL_FILE = `${process.env.REACT_APP_FASTREPORT_ENDPOINT}/files`
// Get Test result detail By Id
const getTestResultDetailById = id => {
    return get(`${BASE_API_URL}/${id}`)
}

// get test result tests by result id
const getTestResultTestsByResultId = id => {
    return get(`${BASE_API_URL}/${id}/test-comments`)
}

const getResultCommentByResultId = id => {
    return get(`${BASE_API_URL}/${id}/result-comment`)
}

const getCategoryCommentByResultId = id => {
    return get(`${BASE_API_URL}/${id}/category-comments`)
}

//get test result profile by result id
const getTestResultProfilesByResultId = id => {
    return get(`${BASE_API_URL}/${id}/profile-comments`)
}

const updateResultCommentProfileById = req => {
    const { resultProfileId } = req
    return put(`${BASE_API_URL}/${resultProfileId}/profile-comment`, req)
}

const updateResultCommentTestById = req => {
    const { resultTestId } = req
    return put(`${BASE_API_URL}/${resultTestId}/test-comment`, req)
}

const getTestResultResultTestByResultId = id => {
    return get(`${BASE_API_URL}/${id}/test-updateresults`)
}

const getUpdatableResultField = () => {
    return get(`${BASE_API_URL}/updatable-result-field`)
}

//get test result profile by result id
const getTestResultResultProfileByResultId = id => {
    return get(`${BASE_API_URL}/${id}/profile-updateresults`)
}

const updateResultResultTestById = req => {
    const { resultTestId } = req
    return put(`${BASE_API_URL}/${resultTestId}/test-updateresults`, req)
}

const getAllTestResults = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}?${q}`)
}

const getAllFullResults = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}?${q}`)
}

// Get Test Requests By Id
const getTestResultById = id => {
    return get(`${BASE_API_URL}/${id}`)
}

const getFullResultById = id => {
    return get(`${BASE_API_URL}/${id}`)
}

const deleteResultsById = params => {
    let ids = ""
    params.forEach(_param => (ids += `id=${_param.id}&`))
    return del(`${BASE_API_URL}?${ids}`)
}

const confirmResultsById = (params, id) => {
    let ids = ""
    params.forEach(_param => (ids += `id=${_param}&`))
    return put(`${BASE_API_URL}/${id}/confirm-results?${ids}`)
}

const unConfirmResultsById = (params, id) => {
    let ids = ""
    params.forEach(_param => (ids += `id=${_param}&`))
    return put(`${BASE_API_URL}/${id}/unconfirm-results?${ids}`)
}

const validResultsById = (params, id, validWithoutDelivery, reportId, data = {}) => {
    let ids = ""
    params.forEach(_param => (ids += `id=${_param}&`))
    return put(`${BASE_API_URL}/${id}/validate-results?reportId=${reportId}&${ids}&validWithoutDelivery=${validWithoutDelivery}`, data)
}

const approvedValidAndPublishResult = (req, data) => {
    const { ResultId } = req
    let q = spreadSearchQuery(req)
    return put(`${BASE_API_URL}/${ResultId}/approved-valid-and-publish-result?${q}`, data)
}

const inValidResultsById = (params, id) => {
    let ids = ""
    params.forEach(_param => (ids += `id=${_param}&`))
    return put(`${BASE_API_URL}/${id}/invalidate-results?${ids}`)
}

const rerunResultsById = (params, id) => {
    let ids = ""
    params.forEach(_param => (ids += `id=${_param}&`))
    return put(`${BASE_API_URL}/${id}/rerun-results?${ids}`)
}

const runRuleResultsById = (params, id, selectedRole) => {
    let ids = ""
    params.forEach(_param => (ids += `resultTestIds=${_param}&`))
    return put(`${BASE_API_URL}/${id}/calculate-rule?selectedRole=${selectedRole}&${ids}`)
}

const warningRuleResultsById = (params, id) => {
    let ids = ""
    params.forEach(_param => (ids += `resultTestIds=${_param}&`))
    return put(`${BASE_API_URL}/${id}/warning-rule?${ids}`)
}

const getTestResultConfigColumn = () => {
    return get(`${BASE_API_URL}/columns-setting`)
}

const updateResultConfigColumns = req => {
    return put(`${BASE_API_URL}/columns-setting`, req)
}

const deleteResultDetailById = (params, id) => {
    let ids = ""
    params.forEach(_param => (ids += `id=${_param}&`))
    return del(`${BASE_API_URL}/${id}/result-tests?${ids}`)
}

const UpdateResultFileId = req => {
    const { id } = req
    return put(`${BASE_API_URL}/${id}/UpdateResultFileId`, req)
}

const getTestPerformResult = (id) => {
    return get(`${BASE_API_URL}/${id}/test-perform`)
}

const getPatientTestHistory = (patientId, testCode) => {
    return get(`${BASE_API_URL}/history?patientId=${patientId}&testCode=${testCode}`)
}

const updateResultCommentById = req => {
    const { id } = req
    return put(`${BASE_API_URL}/${id}/result-comment`, req)
}

const updateCategoryCommentById = req => {
    const { resultId } = req
    return put(`${BASE_API_URL}/${resultId}/category-comment`, req)
}

const createCategoryCommentById = req => {
    const { resultId } = req
    return post(`${BASE_API_URL}/${resultId}/category-comment`, req)
}

const getGeneralHistoryResult = (patientId, fromDate, toDate) => {
    return get(`${BASE_API_URL}/GeneralResultHistory?patientId=${patientId}&fromDate=${fromDate}&toDate=${toDate}`)
}

const getPatientResult = (payload) => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}/PatientResult?${q}`)
}

const getTestResultResultAuditLog = Identifier => {
    // return get(`${BASE_API_URL}/GetTestHistoryAudilog?Identifier=${Identifier}`)
    return get(`${BASE_MS_API_URL}/auditlog?Identifier=${Identifier}`)
}

const getResultNotify = (payload, id) => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}/GetResultNotifications/${id}?${q}`)
}

const createResultNotify = req => {
    return post(`${BASE_API_URL}/CreateResultNotify`, req)
}

const getAuditLog = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_MS_API_URL}/auditlog?${q}`)
}

const getConnectorLog = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_MS_API_URL}/auditlog/GetConnectorLogs?${q}`)
}

const getErrorLog = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_MS_API_URL}/auditlog/GetErrorLogs?${q}`)
}

const updateErrorLog = req => {
    return put(`${BASE_MS_API_URL}/auditlog/UpdateErrorLogs`, req)
}

const getPathologyResult = (id) => {
    return get(`${BASE_API_URL}/GetPathologyResult/${id}`)
}

const updatePathologyResult = (data) => {
    const { resultTestId } = data
    return post(`${BASE_API_URL}/UpdatePathologyResult/${resultTestId}`, data)
}

const updateResultTestVendor = req => {
    return post(`${BASE_API_URL}/UpdateResultTestVendor`, req)
}

const updateResultProfileVendor = req => {
    return post(`${BASE_API_URL}/UpdateResultProfileVendor`, req)
}

const unsignFileResult = (resultId, data) => {
    return put(`${BASE_API_URL}/${resultId}/unsign`, data)
}

const signFileResult = (resultId, data) => {
    return put(`${BASE_API_URL}/${resultId}/sign`, data)
}

const getListFileByItemValue = id => {
    return get(`${BASE_API_URL_FILE}/${id}/file-names`)
}

const getTestHistory = (id) => {
    return get(`${BASE_API_URL}/test-history?resultTestId=${id}`)
}

export {
    getTestHistory,
    getTestResultDetailById,
    getTestResultTestsByResultId,
    getTestResultProfilesByResultId,
    updateResultCommentProfileById,
    updateResultCommentTestById,
    getTestResultResultTestByResultId,
    getTestResultResultProfileByResultId,
    updateResultResultTestById,
    getAllTestResults,
    getAllFullResults,
    getTestResultById,
    getFullResultById,
    deleteResultsById,
    confirmResultsById,
    unConfirmResultsById,
    validResultsById,
    inValidResultsById,
    getTestResultConfigColumn,
    updateResultConfigColumns,
    deleteResultDetailById,
    UpdateResultFileId,
    getTestPerformResult,
    getUpdatableResultField,
    getPatientTestHistory,
    rerunResultsById,
    getResultCommentByResultId,
    getCategoryCommentByResultId,
    updateResultCommentById,
    updateCategoryCommentById,
    createCategoryCommentById,
    runRuleResultsById,
    getGeneralHistoryResult,
    getPatientResult,
    getTestResultResultAuditLog,
    getResultNotify,
    createResultNotify,
    warningRuleResultsById,
    getAuditLog,
    getConnectorLog,
    getErrorLog,
    updateErrorLog,
    approvedValidAndPublishResult,
    getPathologyResult,
    updatePathologyResult,
    updateResultTestVendor,
    updateResultProfileVendor,
    unsignFileResult,
    signFileResult,
    getListFileByItemValue,
}