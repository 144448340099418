// import { AvField, AvRadio, AvRadioGroup } from "availity-reactstrap-validation"
import { AvForm } from "availity-reactstrap-validation"
import { CustomAvField, CustomAvInput, CustomButton, CustomDatePicker, CustomSelect, CustomSelectAsync, DatePicker } from "components/Common"
import { ReportTypes, parameterCode } from "constant/utility"
import { convertDateFormat, getInvalidMessageI18n } from "helpers/utilities"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
//i18n
import { withTranslation } from "react-i18next"
import { Col, Label, Row } from "reactstrap"

const FilterForm = ({ model, t, formEl, onChangeModel, onViewReport }) => {
    const [filterModel, setFilterModel] = useState(model)
    const formRef = formEl ? formEl : useRef();
    const loadingDataExport = true

    useEffect(() => {
        onChangeModel && onChangeModel(filterModel)
    }, [filterModel])

    return (
        <>
            <AvForm ref={formRef}
                // onValidSubmit={(e, vals) => {
                //     onValidSubmit && onValidSubmit(model)
                // }}
                style={{ overflowX: 'hidden', }}
            >
                <Row className="px-2">
                    <Col lg={8} className="my-2">
                        <Row className="align-items-center">

                            <Label for="report" className="font-weight-semibold">
                                {t("Report")}
                            </Label>
                        </Row>
                        <Row>
                            {/* <CustomSelect
                                name="report"
                                value={1}
                                code={parameterCode.DATE_TYPE_RESULT_INPUT_LIST}
                            // label={t("Date Type")}
                            // required
                            // errorMessage={getInvalidMessageI18n(t, "Date Type")}
                            // validate={{
                            //     required: { value: true },
                            // }}
                            // onChange={(e, values) => {
                            //     setModel({ ...model, dateType: values[0] ? values[0] : '' });
                            // }}
                            // readOnly={isUpdate}
                            /> */}
                            <CustomSelectAsync
                                name="report"
                                value={1}
                                options={[
                                    {
                                        label: t('Tổng hợp xét nghiệm gửi'),
                                        value: 1
                                    },
                                    {
                                        label: t('Tổng hợp xét nghiệm theo bệnh nhân'),
                                        value: 2
                                    },
                                    {
                                        label: t('Tổng hợp xét nghiệm theo bộ phận'),
                                        value: 3
                                    }
                                ]}
                            // onChange={(e, values) => {
                            //     setModel({ ...model, state: values[0] ? values[0] : '' });
                            // }}
                            // code={parameterCode.TESTRESULT_STATE_KEY}
                            // placeholder={t("Select State")}
                            />
                        </Row>
                    </Col>

                    <Col lg={2} className="my-2">
                        <Row className="align-items-center">
                            <Label for="FromDate" className="font-weight-semibold">
                                {t("From Date")}
                            </Label>
                        </Row>
                        <Row>
                            {/* <DatePicker
                                name="FromDate"
                                label={t("")}
                                value={filterModel.fromDate}
                                placeholder={"YYYY-MM-DD"}
                                format={"YYYY-MM-DD"}
                                closeOnSelect={true}
                                onChange={date => {
                                    console.log('date', date)
                                    setFilterModel({ ...filterModel, fromDate: convertDateFormat(date.value, "YYYY-MM-DD") })
                                }}
                            /> */}
                            <CustomDatePicker
                                conjunction=" - "
                                name="FromDate"
                                label={""}
                                // checkSameTime={true}
                                format={"YYYY-MM-DD"}
                                value={filterModel.fromDate}
                                closeOnSelect={true}
                                onChangeHandler={date => {
                                    setFilterModel({ ...filterModel, fromDate: convertDateFormat(date.value, "YYYY-MM-DD") })
                                }}
                                maxDate={new Date()}
                            // minDate={minDate}
                            // mode="range"
                            />
                        </Row>

                    </Col>
                    <Col lg={2} className="my-2">
                        <Row className="align-items-center">
                            <Label for="ToDate" className="font-weight-semibold">
                                {t("To Date")}
                            </Label>
                        </Row>
                        <Row>
                            {/* <DatePicker
                                name="ToDate"
                                label={t("")}
                                value={filterModel.toDate}
                                placeholder={"YYYY-MM-DD"}
                                format={"YYYY-MM-DD"}
                                closeOnSelect={true}
                                onChange={date => {
                                    setFilterModel({ ...filterModel, toDate: convertDateFormat(date.value, "YYYY-MM-DD") })
                                }}
                            /> */}
                            <CustomDatePicker
                                conjunction=" - "
                                name="ToDate"
                                label={""}
                                // checkSameTime={true}
                                format={"YYYY-MM-DD"}
                                value={filterModel.toDate}
                                closeOnSelect={true}
                                onChangeHandler={date => {
                                    setFilterModel({ ...filterModel, toDate: convertDateFormat(date.value, "YYYY-MM-DD") })
                                }}
                                maxDate={new Date()}
                            // minDate={minDate}
                            // mode="range"
                            />
                        </Row>
                    </Col>
                </Row>

                <Row className="px-2">
                    <Col lg={2} className="my-2">
                        <Row className="align-items-center">
                            <Label for="Category" className="font-weight-semibold">
                                {t("Category")}
                            </Label>
                        </Row>
                        <Row>
                            <CustomSelect
                                name="Category"
                                value={filterModel.category}
                                code={parameterCode.TEST_CATEGORIES}
                                onChange={(e, values) => {
                                    setFilterModel({ ...filterModel, category: values[0] ? values[0] : '' });
                                }}
                            />
                        </Row>

                    </Col>
                    <Col lg={2} className="my-2">
                        <Row className="align-items-center">
                            <Label for="Search" className="font-weight-semibold">
                                {t("Search")}
                            </Label>
                        </Row>
                        <Row>
                            <CustomAvInput
                                name={'Search'}
                                placeholder={t("Tìm theo PID,...")}
                                value={filterModel.searchText}
                                onChange={e => {
                                    setFilterModel({ ...filterModel, PID: e.target.value || '' })
                                }}
                            />
                        </Row>
                    </Col>

                    <Col lg={2} className="my-2">
                        <Row className="align-items-center">
                            <Label for="ServiceType" className="font-weight-semibold">
                                {t("Service Type")}
                            </Label>
                        </Row>
                        <Row>
                            <CustomSelect
                                name="ServiceType"
                                value={filterModel.serviceType}
                                code={parameterCode.SERVICE_TYPE}
                                onChange={(e, vals, a, values) => {
                                    setFilterModel({ ...filterModel, serviceType: values[0]?.code || '' });
                                }}
                            />
                        </Row>

                    </Col>
                    <Col lg={2} className="my-2">
                        <Row className="align-items-center">
                            <Label for="State" className="font-weight-semibold">
                                {t("State")}
                            </Label>
                        </Row>
                        <Row>
                            <CustomSelectAsync
                                name="State"
                                value={filterModel.state}
                                options={[
                                    {
                                        label: t('Delivered'),
                                        value: 4
                                    },
                                    {
                                        label: t('Completed'),
                                        value: 8
                                    },
                                    {
                                        label: t('Validated'),
                                        value: 90
                                    },
                                    {
                                        label: t('Released'),
                                        value: 99
                                    }
                                ]}
                                onChange={(e, values) => {
                                    setFilterModel({ ...filterModel, state: values[0] ? values[0] : '' });
                                }}
                                code={parameterCode.TESTRESULT_STATE_KEY}
                                placeholder={t("common:Select Custom")}
                            />
                        </Row>
                    </Col>

                    <Col lg={2} className="my-2">
                        <Row className="align-items-center">
                            <Label for="VendorCode" className="font-weight-semibold">
                                {t("Vendor Code")}
                            </Label>
                        </Row>
                        <Row>
                            <CustomSelectAsync
                                name="VendorCode"
                                value={filterModel.vendorCode || ""}
                                code={parameterCode.ORGANIZATIONS}
                                // searchQuery={{ page: 1, profileId: 5 }}
                                onChange={(e, vals, a, values) => {
                                    setFilterModel({ ...filterModel, vendorId: vals[0] || '' });
                                }}
                                placeholder={t("common:Select Custom")}
                            />
                        </Row>

                    </Col>
                    <Col lg={2} className="my-2">
                        <Row className="align-items-center">
                            <Label for="Group Code" className="font-weight-semibold">
                                {t("Group")}
                            </Label>
                        </Row>
                        <Row>
                            <CustomSelect
                                name="GroupCode"
                                value={filterModel.groupCode}
                                code={parameterCode.ALL_GROUP}
                                // placeholder={t("Select Group")}

                                onChange={(e, vals, a, values) => {
                                    setFilterModel({ ...filterModel, groupCode: '' });
                                }}
                            />
                        </Row>
                    </Col>
                </Row>
                <Row style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                    <Col lg={3} style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                        <CustomButton
                            color="success"
                            style={{ marginRight: 8 }}
                            onClick={() => {
                                onViewReport && onViewReport()
                            }}
                        >
                            <div className="d-flex">
                                <div><i className="fa fa-search"></i></div>
                                <div style={{ marginLeft: 10, marginRight: 10 }}>{t("common:View Report")}</div>
                            </div>
                        </CustomButton>
                    </Col>
                </Row>
            </AvForm>
        </>
    )
}
FilterForm.propTypes = {
    model: PropTypes.object,
    t: PropTypes.any,
}
export default withTranslation(["reportPage", "common"])(FilterForm)