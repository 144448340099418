import i18n from "i18next";
import { call, put, takeEvery } from "redux-saga/effects";
import {
    CONFIRM_WORK_LIST,
    GET_WORK_LIST, GET_WORK_LIST_EXPORT, INVALID_WORK_LIST, UNCONFIRM_WORK_LIST, UPDATE_WORK_LIST, VALID_WORK_LIST
} from "./actionTypes";

import { showToast } from "components/Common";
import { getAllUsers } from "helpers/app-backend";
import { confirmResultsById, getTestWorkLists, inValidWorkLists, unConfirmResultsById, updateWorkLists, validWorkLists } from "helpers/app-backend/workList_backend_helper";
import { convertDateFormat, ifNull } from "helpers/utilities";
import { confirmWorkListsFail, getWorkListsExportFail, getWorkListsExportSuccess, getWorkListsFail, getWorkListsSuccess, unConfirmWorkListsFail, updateWorkListsFail, updateWorkListsSuccess } from "./actions";

const t = (msg, param) => i18n.t(msg, param)
function* fetchWorkLists({ payload, callback }) {
    try {
        payload.fromDate = convertDateFormat(payload?.fromDate || new Date(), "YYYY-MM-DD")
        payload.toDate = convertDateFormat(payload?.toDate || new Date(), "YYYY-MM-DD")
        if (payload.testName)
            delete payload.testName
        const response = yield call(getTestWorkLists, payload)

        const arrUserCreate = response.data.filter(x => x.createdBy).map(x => x.createdBy) || [];
        const arrUserValid = response.data.filter(x => x.validatedBy).map(x => x.validatedBy) || [];
        const arrUserConfirm = response.data.filter(x => x.confirmedBy).map(x => x.confirmedBy) || [];     
        const arrUser = [...arrUserCreate, ...arrUserValid, ...arrUserConfirm]
        const arrUserIdDistinct = arrUser.filter((x, index) => !arrUser.includes(x, index + 1)) || []
        let resUser = [];
        if (arrUserIdDistinct.length > 0)
            resUser = yield call(getAllUsers, { id: arrUserIdDistinct })

        let res = response.data || [];
        if (resUser.data?.length > 0) {
            res.forEach((element, _index) => {
                const itemUserCreate = resUser.data?.find(x => x.id == element.createdBy)
                const itemUserValid = resUser.data?.find(x => x.id == element.validatedBy)
                const itemUserConfirm= resUser.data?.find(x => x.id == element.confirmedBy)
                const assignUserName = (user) => {
                    return user ? "".concat(ifNull(user.familyName), ifNull(" " + user.givenName)) : '';
                };
                if (itemUserCreate) {
                    element.createdByName = assignUserName(itemUserCreate);
                }
        
                if (itemUserValid && itemUserConfirm) {
                     if(assignUserName(itemUserConfirm) === assignUserName(itemUserValid)){
                        element.user = assignUserName(itemUserValid)
                     }
                     else {
                        element.user = `${assignUserName(itemUserConfirm)} / ${assignUserName(itemUserValid)}`;
                     }     
                } else if (itemUserValid) {
                    element.user = assignUserName(itemUserValid);
                } else if (itemUserConfirm) {
                    element.user = assignUserName(itemUserConfirm);       
                }
            })
        }
        response.data = res.map(x => ({ ...x, id: x.resultTestId }))
        if (callback) {
            yield put(getWorkListsExportSuccess(response))
            callback(response.data)
        }
        else {
            yield put(getWorkListsSuccess(response))
        }
    } catch (error) {
        console.log(error);
        yield put(getWorkListsFail(error))
        yield put(getWorkListsExportFail(error))
    }
}

function* onUpdateWorkList({ payload: { filter, callback, newResult, newResultText, selectedRole, id } }) {
    try {
        const data = {
            filter, newResult, newResultText, selectedRole, id
        }
        const response = yield call(updateWorkLists, data)
        yield put(updateWorkListsSuccess())

        showToast(
            `${t("message:UpdatedMessage", {
                field: `${t("workListPage:Update Result")}`,
            })}`
        )
        callback && callback()
    } catch (error) {
        console.log(error)
        yield put(updateWorkListsFail(error))
    }
}

function* onValidWorkList({ payload: { filter, callback, newResult, id } }) {
    try {
        const data = {
            filter, newResult, id
        }
        yield call(validWorkLists, data)
        yield put(updateWorkListsSuccess())

        showToast(
            `${t("message:UpdatedMessage", {
                field: `${t("workListPage:Valid Result")}`,
            })}`
        )
        callback && callback()
    } catch (error) {
        callback && callback()
        yield put(updateWorkListsFail(error))
    }
}

function* onInvalidWorkList({ payload: { ids, id, sid, callback } }) {
    try {
        yield call(inValidWorkLists, ids, id)
        yield put(updateWorkListsSuccess())
        showToast(
            `${t("message:UpdatedMessage", {
                field: sid,
            })}`
        )
        callback && callback()
    } catch (error) {
        yield put(updateWorkListsFail(error))
    }
}

function* onConfirmWorkList({ payload: { ids, id, sid, callback } }) {
    try {
        yield call(confirmResultsById, ids, id)
        showToast(
            `${t("message:ConfirmResultSuccessed", {
                field: sid,
            })}`
        )
        callback && callback()
    } catch (error) {
        yield put(confirmWorkListsFail(error))
    }
}

function* onUnConfirmWorkList({ payload: { ids, id, sid, callback } }) {
    try {
        yield call(unConfirmResultsById, ids, id)
        showToast(
            `${t("message:UnConfirmResultSuccessed", {
                field: sid,
            })}`
        )
        callback && callback()
    } catch (error) {
        yield put(unConfirmWorkListsFail(error))
    }
}

function* workListSaga() {
    yield takeEvery(GET_WORK_LIST, fetchWorkLists)
    yield takeEvery(GET_WORK_LIST_EXPORT, fetchWorkLists)
    yield takeEvery(UPDATE_WORK_LIST, onUpdateWorkList)
    yield takeEvery(VALID_WORK_LIST, onValidWorkList)
    yield takeEvery(INVALID_WORK_LIST, onInvalidWorkList)
    yield takeEvery(CONFIRM_WORK_LIST, onConfirmWorkList)
    yield takeEvery(UNCONFIRM_WORK_LIST, onUnConfirmWorkList)

}
export default workListSaga