import { convertDateFormat, spreadSearchQuery } from "helpers/utilities"
import { get, put, post } from "../api_helper2"

const BASE_API_URL = `${process.env.REACT_APP_LABORATORY_ENDPOINT}/worklists`

// Get All Test Requests
const getTestWorkLists = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}?${q}`)
}

const updateWorkLists = req => {
    return put(`${BASE_API_URL}/update-result`, req)
}

const validWorkLists = req => {
    return put(`${BASE_API_URL}/valid-result`, req)
}

const inValidWorkLists = (params, id) => {
    let ids = ""
    params.forEach(_param => (ids += `id=${_param}&`))
    return put(`${BASE_API_URL}/${id}/invalid-result?${ids}`)
}

const confirmResultsById = (params, id) => {
    let ids = ""
    params.forEach(_param => (ids += `id=${_param}&`))
    return put(`${BASE_API_URL}/${id}/confirm-results?${ids}`)
}

const unConfirmResultsById = (params, id) => {
    let ids = ""
    params.forEach(_param => (ids += `id=${_param}&`))
    return put(`${BASE_API_URL}/${id}/unconfirm-results?${ids}`)
}

const exportWorkList = (payload) => {
    payload.fromDate = convertDateFormat(payload.fromDate, "YYYY-MM-DD"),
        payload.toDate = convertDateFormat(payload.toDate, "YYYY-MM-DD")
    let q = spreadSearchQuery(payload)
    return post(`${BASE_API_URL}/export-work-list?${q}`, null, {
        responseType: 'arraybuffer',
        headers:
        {
            'Content-Disposition': "attachment; filename=ExportWorkList.xlsx",
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
    })
}

export {
    getTestWorkLists,
    updateWorkLists,
    validWorkLists,
    inValidWorkLists,
    confirmResultsById,
    unConfirmResultsById,
    exportWorkList
}

