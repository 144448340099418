import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import { CustomBootstrapTable } from "components/Common";
import { convertDateFormat, convertDateFormat_VN, indexCalculator } from "helpers/utilities";

import FilterForm from "./FilterForm";

//i18n
import BadgeLabel from "components/Common/BadgeLabel";
import CustomSIDHover from "components/Common/Input/CustomSIDHover";
import { ModuleIds, RESULT_STATE } from "constant";
import { withTranslation } from "react-i18next";
import { UncontrolledTooltip } from "reactstrap";
import TestRequestAction from "./TestRequestAction";
import TestRequestTableButtons from "./TestRequestTableButtons";
import ImportExportButton from "./ImportExportForm"

const RESOURCE = ModuleIds.TestRequest;

const TestRequestTable = ({
    onSelect,
    onSelectAll,
    testRequests,
    onSearch,
    onSort,
    onRefresh,
    onPageChange,
    paging,
    onSizePerPageChange,
    onSubmitFilter,
    onClone,
    onCancel,
    loading,
    t,
    updatedTime,
    onChangeModel,
    onExportRequestList,
}) => {
    const [data, setData] = useState([]);
    const [model, setModel] = useState({
        search: "",
        start: convertDateFormat(new Date(), "YYYY-MM-DD"),
        end: convertDateFormat(new Date(), "YYYY-MM-DD"),
        physicianId: "",
        departmentId: "",
        state: "",
        serviceType: "",
    });
    const searchQuery = useSelector(state => state.testRequest.searchQuery);
    const defaultSortValue = { dataField: "createdDate", order: "desc" }
    const onResetHandler = () => {
        const initModel = {
            search: "",
            start: convertDateFormat(new Date(), "YYYY-MM-DD"),
            end: convertDateFormat(new Date(), "YYYY-MM-DD"),
            physicianId: "",
            departmentId: "",
            state: "",
            serviceType: "",
        }
        setModel(initModel)
    }

    useEffect(() => {
        setModel(searchQuery)
    }, [searchQuery])

    useEffect(() => {
        setData(testRequests)
    }, [testRequests])

    const columns = [
        {
            dataField: "id",
            text: "#",
            hidden: true,
            formatter: (cellContent, testRequest, index) => {
                return cellContent
            },
        },
        {
            dataField: "SEQ",
            text: "#",
            formatter: (cellContent, testRequest, index) => {
                return indexCalculator(paging.page, paging.size, index + 1)
            },
        },
        {
            dataField: "createdDate",
            text: t("Request Date"),
            sort: true,
            formatter: (cellContent, testRequest, index) => {
                return (<div>
                    <Link id={`requestDateId-${index}`} to={`/TestRequest/${testRequest.id}/view?tab=1`}>{`${convertDateFormat(cellContent, "DD-MM-YYYY")}`}</Link>          
                </div>)
            },
        },
        {
            dataField: "medicalId",
            text: t("Medical ID"),
        },
        {
            dataField: "SID",
            text: t("SID"),
            formatter: (cellContent, testRequest, indexParent) => {
                return <CustomSIDHover placement="bottom" data={testRequest.sampleIds} indexParent={indexParent} emergency={testRequest.emergency} />
            },
        },
        {
            dataField: "patientName",
            text: t("Patient"),
            sort: true,
            formatter: (cellContent, testRequest, index) => {
                return <Link to={`/Patient/${testRequest.patientId}/view?tab=1`} target="_blank">
                    {`${testRequest.patientName}` + (testRequest.genderName ? ` - ${testRequest.genderName}` : '')}
                </Link>
            },
        },
        // {
        //     dataField: "patientId",
        //     text: "PID",
        //     sort: true,
        //     formatter: (cellContent, testRequest, index) => {
        //         return cellContent != 'undefined' ? cellContent : ''
        //     },
        // },
        {
            dataField: "dob",
            text: t("Birthday"),
            formatter: (cellContent, testRequest, index) => {
                return convertDateFormat_VN(testRequest.dob)
            },
        },
        {
            dataField: "serviceTypeName",
            text: t("Service Type"),
        },
        {
            dataField: "departmentName",
            text: t("Department"),
            formatter: (cellContent, testRequest, index) => {
                return <React.Fragment>
                    {testRequest.departmentId ?
                        <Link to={`/department/${testRequest.departmentId}/view?tab=1`} target="_blank">{`${testRequest.departmentName}`}</Link>
                        :
                        <span></span>
                    }
                </React.Fragment>
            },
        },
        {
            dataField: "state",
            headerStyle: { width: "110px" },
            sort: true,
            text: t("State"),
            align: 'center',
            headerAlign: 'center',
            formatter: (cellContent, testRequest, index) => {
                return <BadgeLabel
                    value={t(`${RESULT_STATE[testRequest.state]}`)}
                    type={RESULT_STATE[testRequest.state]}
                    className="badge-width"
                />
            },
        },
        {
            dataField: "groupCode",
            text: t("Group Code"),
        },
        {
            dataField: "action",
            text: "",
            isDummyField: true,
            style: { width: 50 },
            formatter: (cellContent, param, index) => (
                <TestRequestAction
                    onCancel={onCancel}
                    onClone={onClone}
                    index={index}
                    param={param}
                />
            ),
        },
    ]

    useEffect(() => {
        onChangeModel && onChangeModel(model)
    }, [model])

    return (
        <div className="test-request-table-toolbar">
            <CustomBootstrapTable
                columns={columns}
                search
                data={data}
                paging={paging}
                onSelect={onSelect}
                onSelectAll={onSelectAll}
                onSearch={val => {
                    setModel({ ...model, search: val })
                    setTimeout(() => {
                        onSearch(val)
                    }, 100);
                }}
                onSort={onSort}
                onRefresh={onRefresh}
                filterForm={() => <FilterForm model={model} />}
                onReset={onResetHandler}
                onPageChange={onPageChange}
                onSizePerPageChange={onSizePerPageChange}
                onSubmitFilter={onSubmitFilter}
                searchText={model.search}
                loading={loading}
                updatedTime={updatedTime}
                resource={RESOURCE}
                keyField="id"
                defaultSortValue={defaultSortValue}
                customButtonTable={() =>
                    <TestRequestTableButtons
                        t={t}
                        model={model}
                        onChange={val => {
                            setModel({ ...model, ...val })
                        }}
                        onSubmit={(val) => {
                            onSubmitFilter(val)
                        }}
                    />
                }
                isImportExportButton={true}
                importExportForm={() => <ImportExportButton onExportRequestList={onExportRequestList} />}
            />
        </div>
    )
}

export default withTranslation(["testRequestPage"])(TestRequestTable)