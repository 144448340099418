import { CustomButton, FilterButton } from "components/Common"
import CustomBootstrapTableAsync from "components/Common/CustomTable/CustomBootstrapTableAsync"
import { convertDateFormat, convertDateFormat_VN, indexCalculator } from "helpers/utilities"
import { withTranslation } from "react-i18next"
import ReactTooltip from "react-tooltip"
import FilterForm from "./FilterForm"
import { ModuleIds } from "constant"

const RESOURCE = ModuleIds.PatientVisit

const WatingRequest = ({
  data,
  t,
  pagingWaiting,
  onResetHandler,
  onSubmitFilterPatient,
  onSidUpdate,
  model
}) => {

  let countTotal = 0

  const columns = [
    {
      dataField: "id",
      text: "#",
      style: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, item, index) => {
        if (item.id == -1) {
          return <span id={`watch_end_of_document1`}></span>
        }
        else
          return indexCalculator(0, 0, index + 1)
      },
    },
    {
      dataField: "requestDate",
      text: t("patientVisitPage:Date"),
      formatter: (cellContent, item, index) => {
        return convertDateFormat(item.createdDate, "YYYY-MM-DD")
      },
    },
    {
      dataField: "serviceTypeName",
      text: t("common:ServiceType"),
      formatter: (cellContent, item, index) => {
        return cellContent
      },
    },
    {
      dataField: "",
      text: "",
      formatter: (cellContent, item, index) => {
        return (
          <div className="d-flex">
            {item?.groupCode && <i
              key={index}
              data-tip
              data-for={`groupCode-${index}`}
              className="mdi mdi-bookmark"
              style={{
                fontSize: 'larger',
                color: 'purple'
              }}
            >
              {item.organizationName && <ReactTooltip id={`groupCode-${index}`} place="top" effect="solid">
                {item.organizationName || ''}
              </ReactTooltip>}
            </i>}
          </div>
        )
      }
    },
    {
      dataField: "patientName",
      text: t("Name"),
      formatter: (cellContent, item, index) => {
        return (
          <div style={{ color: "#3c3ce4", paddingTop: '2px' }}>{item.patientName}</div>
        )
      }
    },
    {
      dataField: "patientId",
      text: t("PID"),
      formatter: (cellContent, item, index) => {
        return item.patientId
      }
    },
    {
      dataField: "age",
      text: t("DOB"),
      formatter: (cellContent, item, index) => item.dob && <div>{convertDateFormat_VN(item.dob, "DD-MM-YYYY")}</div>
    },
    {
      dataField: "update",
      text: t(""),
      formatter: (cellContent, item, index) => {
        return <div onClick={onSidUpdate}>
          <i className="bx bx-right-arrow-circle font-size-22 " style={{ color: "#3c3ce4" }} />
        </div>
      }
    }
  ]

  const getDataDisplay = () => {
    let res = [...data]
    if (res.length > 0) {
      res.push({ id: -1, testCode: '' })
    }
    countTotal = res.length
    return res
  }

  return (
    <div className={`table-patient-visit ${data.length > 0 ? 'table-expand-custom table-rule-management' : ''}  `} style={{ height: '100%' }}>
      <CustomBootstrapTableAsync
        TableTitle={t('Patient List')}
        columns={columns}
        data={getDataDisplay()}
        keyField="id"
        onSizePerPageChange={() => { }}
        showSelectRow={false}
        paging={pagingWaiting}
        isScrollable={true}
        style={{ height: '270px' }}
        resource={RESOURCE}
        onRefresh={() => { onResetHandler('1') }}
        customButtonRight={() => (
          <>
            <FilterButton
              onReset={onResetHandler}
              onSubmit={onSubmitFilterPatient}
              direction={'end'}
              className={''}
            >
              <FilterForm model={model} />
            </FilterButton>
            <i
              className="font-size-24 bx bx-right-arrow-circle p-0 btn btn-link"
              style={{ color: "#3c3ce4" }}
            />
          </>

        )}
      />
    </div>
  )
}
export default withTranslation(["testRequestPage", "message", "patientVisitPage"])(WatingRequest)
